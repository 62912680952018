
import { Component, Vue, Watch, Prop, Mixins } from "vue-property-decorator";
import { Firearms, AROs, Documents, CRUDFunctionality } from "@/hooks";
import { Level } from "@/enums/level";
import VuePdfApp from "vue-pdf-app";
import dayjs from "dayjs";
import {
  ArmResponseOfficer,
  Firearm,
  PsiraRegistrationDocuments,
  PsiraRegistrationDocuments_Create,
} from "models";
import { axiosInstance, axiosNoAuth } from "@/plugins/axios";
import Docs from "@/store/modules/Documents";
import FileMixin from "@/mixins/FileMixin.vue";

interface Certificate {
  armResponseOfficerID: number;
  assessmentStatementOfResultsFileName: string;
  assessmentStatementOfResultsFilePath: string;
  conductOBEAssessmentFileName: string;
  conductOBEAssessmentFilePath: string;
  createdBy: string;
  createdDate: string;
  expiryDate: string;
  gradingShootFileName: string;
  gradingShootFilePath: string;
  id: number;
  level: number;
  superviseFirearmTrainingFileName: string;
  superviseFirearmTrainingFilePath: string | null;
  trainingStatementOfResultsFileName: string;
  trainingStatementOfResultsFilePath: string | null;
  updatedBy: string;
  updatedDate: string;
}

@Component({
  components: {
    VuePdfApp,
  },
})
export default class FirearmInstructorUnitStandards extends Mixins(FileMixin) {
  @Prop() certificate!: Certificate | null;
  @Prop() aroId!: number;
  isOpenExpiryDate = false;
  isOpenRenewalProofDate = false;
  loading = false;

  certPreview = "";
  sorPreview = "";
  get getRules() {
    return {
      required: (v: string) => !!v || "Required",
    };
  }
  get getAroDocs() {
    return Docs.getAroDocs;
  }
  preview = {
    training: false,
    trainingSor: false,
    coa: false,
    coaSor: false,
    grading: false,
  };
  expanded = {
    training: false,
    trainingSor: false,
    coa: false,
    coaSor: false,
    grading: false,
  };

  currentDoc: {
    InstructorExpiryDate: string | null;
    TrainingFile: File | null;
    TrainingResultsFile: File | null;
    GradingFile: File | null;
    AssessmentFile: File | null;
    AssessmentResultsFile: File | null;
    Level: Level.Basic;
  } = {
    InstructorExpiryDate: null,
    TrainingFile: null,
    TrainingResultsFile: null,
    GradingFile: null,
    AssessmentFile: null,
    AssessmentResultsFile: null,
    Level: Level.Basic,
  };

  shouldDisplayForRole(name: string | undefined): boolean {
    const has = CRUDFunctionality.hasAccess(name);
    return !has;
  }

  get getCurrentDoc() {
    return this.currentDoc;
  }

  async deleteFile(file: number): Promise<void> {
    try {
      this.loading = true;
      if (this.getAroDocs) {
        const fileId = this.getAroDocs.instructorUnitStandards[0].id;

        const res = await Documents.deleteInstructor(fileId, file);
      }
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.$emit("refresh");
      this.loading = false;
    }
  }

  validate(): void {
    //   console.log("Outside validate");
    if (this.$refs.form) {
      //   console.log("Outside inside");
      (this.$refs.form as any).validate();
    }
  }
  URL = URL;
  errorHandler(e: any) {
    //   console.log("Erroror", e);
  }
  valid = false;

  async addInstructorUS(): Promise<void> {
    try {
      this.loading = true;

      const formData = new FormData();
      if (this.currentDoc != null) {
        Object.entries(this.currentDoc).forEach(([key, value]) => {
          if (value == null) {
            value = "";
          }
          if (typeof value === "number") {
            formData.append(key, value.toString());
          } else {
            formData.append(key, value);
          }
        });
      }
      if (this.getAroDocs) {
        formData.append("InstructorAROID", this.getAroDocs.id.toString());
      }

      // if(this.currentDoc != null) {
      //   psiraFile.append("file" , new Blob(this.currentDoc.PSIRAFile))
      // }
      // ;
      //   console.log("Form data ", formData);
      const res = await axiosInstance.post(
        "/api/ManageDocumentsAPI/addInstructorUnitStandards",
        formData
      );
      this.$emit("refresh");
      //   console.log("Add Reg response", res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  async init(): Promise<void> {
    if (this.certificate != null) {
      const res = await Promise.all([
        Documents.getFile(this.certificate.superviseFirearmTrainingFilePath),
        Documents.getFile(this.certificate.trainingStatementOfResultsFilePath),
        Documents.getFile(this.certificate.gradingShootFilePath),
        Documents.getFile(this.certificate.conductOBEAssessmentFilePath),
        Documents.getFile(
          this.certificate.assessmentStatementOfResultsFilePath
        ),
      ]);

      const psira = res[0];
      const renewed = res[1];

      this.currentDoc = {
        InstructorExpiryDate: null,
        TrainingFile: null,
        TrainingResultsFile: null,
        GradingFile: null,
        AssessmentFile: null,
        AssessmentResultsFile: null,
        Level: Level.Basic,
      };
    } else {
      this.currentDoc = {
        InstructorExpiryDate: null,
        TrainingFile: null,
        TrainingResultsFile: null,
        GradingFile: null,
        AssessmentFile: null,
        AssessmentResultsFile: null,
        Level: Level.Basic,
      };
    }
  }
  @Watch("docs", {
    immediate: true,
  })
  async onDocsChanged(val: PsiraRegistrationDocuments[]): Promise<void> {
    //   console.log("Docs changed ! ---", val);
    if (val) await this.init();
  }
  async mounted(): Promise<void> {
    //   console.log("Mounted PSira Reg", this.currentDoc);
    this.init();
  }
}
