import _Vue from "vue";
import { FirebaseApp, FirebaseOptions, initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  Messaging,
  onMessage,
  isSupported,
} from "firebase/messaging";

export interface fbHookType {
  fbConfig: FirebaseOptions;
  getFbApp: () => FirebaseApp | null;
  getFbMessaging: () => Messaging | null;
  initApp: () => void;
  subscribeOnMessage: () => void;
  // fbAnalytics: Analytics;
  getTokenAsync: (
    serviceWorkerRegistration: ServiceWorkerRegistration
  ) => Promise<string>;
  isSupported: () => Promise<boolean>;
}
export const firebaseHook = (): fbHookType => {
  const firebaseConfig: FirebaseOptions = {
    apiKey: "AIzaSyBnmUxWrVBSi1H8bzmsH1MS_gTwwp-Gm3U",
    authDomain: "zero8-e6ca8.firebaseapp.com",
    projectId: "zero8-e6ca8",
    storageBucket: "zero8-e6ca8.appspot.com",
    messagingSenderId: "714877315059",
    appId: "1:714877315059:web:94cfce5f025722b9411486",
    measurementId: "G-5WJYM239ST",
  };

  let firebaseApp: FirebaseApp | null = null;
  let messaging: Messaging | null = null;

  const initApp = () => {
    firebaseApp = initializeApp(firebaseConfig);
    messaging = getMessaging(firebaseApp);
    //console.log("Init test: ", firebaseApp, messaging);
    onMessage(messaging, (payload) => {
      console.log("PLEASE: Message received. ", payload);
      // emit event here
    });
    //
  };

  const subscribeOnMessage = (): void => {
    const msg = getFbMessaging();
    if (msg == null) {
      console.error("Cannot subscribe to on message == null");
      return;
    }
    console.log("Do listening to message");
    onMessage(msg, (payload) => {
      console.log("PLEASE: Message received. ", payload);
      // emit event here
    });
  };
  // const analytics = getAnalytics(firebaseApp);
  const getFbApp = (): FirebaseApp | null => {
    return firebaseApp;
  };
  const getFbMessaging = (): Messaging | null => {
    return messaging;
  };

  const getTokenAsync = async (
    serviceWorkerRegistration: ServiceWorkerRegistration
  ): Promise<string> => {
    try {
      const msg = getFbMessaging();
      if (msg == null) {
        console.log("Firebase Messaging == null");
        return Promise.reject("Firebase Messaging == null");
      }
      console.log("Going to get token");
      const token = await getToken(msg, {
        vapidKey:
          "BB_gG03mz7Gr8cI1p0sMtNrWcPodDJM8zF6ptuk6XzjYiVabfcf320fr0-fIIkJgNTjI7TmU7TLGsV0eGh0D0F4",
        serviceWorkerRegistration: serviceWorkerRegistration,
      }).catch((err) => {
        console.log("GET_TOKEN err: ", err);
        return Promise.reject(err);
      });

      if (token) {
        console.log("Registration Token Available", token);
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
      return Promise.resolve(token);
    } catch (err) {
      console.log("GET_TOKEN_ASYNC err: ", err);
      return Promise.reject(err);
    }
  };

  return {
    fbConfig: firebaseConfig,
    getFbMessaging,
    getFbApp,
    initApp,
    subscribeOnMessage,
    // fbAnalytics: analytics,
    getTokenAsync,
    isSupported,
  };
};
const fbHook = firebaseHook();
export default fbHook;
