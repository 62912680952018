import { axiosInstance } from "@/plugins/axios";
import { Incident } from "models";

const Incidents = () => {
  const baseUrl = "/api/incidentsAPI";

  const getIncidents = async (): Promise<Incident[]> => {
    const res = await axiosInstance.get(baseUrl + "/getIncident");
    return Promise.resolve(res.data);
  };

  const getHistoricIncidents = async (): Promise<any> => {
    const res = await axiosInstance.get(baseUrl + "/getHistoricIncident");
    return Promise.resolve(res.data);
  };

  const getIncidentTypeAndAROsFirearms = async (): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + "/getIncidentTypeAndAROsFirearms"
    );
    return Promise.resolve(res.data);
  };

  const addIncident = async (data: FormData): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/addIncident", data);
    return Promise.resolve(res.data);
  };

  const editIncident = async (data: FormData): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/editIncident", data);
    return Promise.resolve(res.data);
  };

  const deleteIncident = async (id: number): Promise<void> => {
    const res = await axiosInstance.delete(
      baseUrl + `/deleteIncident?incidentID=${id}`
    );
    return Promise.resolve(res.data);
  };

  const getIncidentPicturesByIncidentID = async (id: number): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getIncidentPicturesByIncidentID?incidentID=${id}`
    );
    return Promise.resolve(res.data);
  };

  const getFiles = async (id: number): Promise<any> => {
    const res = await axiosInstance.get(baseUrl + `/getFiles?id=${id}`);
    return Promise.resolve(res.data);
  };

  const downLoadFile = async (id: number): Promise<any> => {
    const res = await axiosInstance.get(baseUrl + `/downLoadFile?id=${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "image/jpeg" + "application/pdf" + "image/png",
      },
      responseType: "blob",
    });
    return Promise.resolve(res.data);
  };

  const downLoadImage = async (id: number): Promise<any> => {
    const res = await axiosInstance.get(baseUrl + `/downLoadImage?id=${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "image/jpeg" + "application/pdf" + "image/png",
      },
      responseType: "blob",
    });
    return Promise.resolve(res.data);
  };

  const addIncidentFile = async (data: FormData): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + `/addIncidentFile`, data);
    return Promise.resolve(res.data);
  };

  const addIncidentImage = async (data: FormData): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + `/addIncidentImage`, data);
    return Promise.resolve(res.data);
  };

  return {
    getIncidents,
    getHistoricIncidents,
    getIncidentTypeAndAROsFirearms,
    addIncident,
    editIncident,
    deleteIncident,
    getIncidentPicturesByIncidentID,
    downLoadFile,
    downLoadImage,
    addIncidentFile,
    addIncidentImage,
    getFiles,
  };
};
const temp = Incidents();
export default temp;
