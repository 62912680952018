import { axiosInstance } from "@/plugins/axios";
import dayjs from "dayjs";
import { Firearm, Certification } from "models";

const Firearms = (): any => {
  const baseUrl = "/api/firearmsAPI";
  const validateFirearm = async (
    firearmId: number,
    aroId: number
  ): Promise<{
    status: number;
    message: string;
    sapsCompetencyCertificate?: Certification;
  }> => {
    const res = await axiosInstance.get(
      baseUrl + "/ValidateFirearm?firearmID=" + firearmId + "&aroID=" + aroId
    );
    return Promise.resolve(res.data);
  };

  const getFirearmByIDAsync = async (id: number): Promise<Firearm> => {
    const res = await axiosInstance.get(
      baseUrl + "/getFirearmByIDAsync?firearmID=" + id
    );
    return Promise.resolve(res.data);
  };

  const getFirearmByIDAsyncForIncident = async (id: number): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + "/getFirearmByIDForIncident?firearmID=" + id
    );
    return Promise.resolve(res.data);
  };

  const getAllFirearms = async (): Promise<Firearm[]> => {
    const res = await axiosInstance.get(baseUrl + "/getFirearm");
    return Promise.resolve(res.data);
  };

  const addFirearm = async (data: FormData): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/addFirearm", data);
    return Promise.resolve(res.data);
  };

  const editFirearm = async (data: FormData): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/editFirearm", data);
    return Promise.resolve(res.data);
  };

  const deleteFirearm = async (
    id: number,
    reason: string,
    otherReason: string
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl +
        `/softDeleteFirearm?firearmID=${id}&radioDeleteReason=${reason}&otherReason=${otherReason}`
    );
    return Promise.resolve(res.data);
  };

  const archiveFirearm = async (
    id: number,
    reason: string,
    SAP13Number: string,
    CASNumber: string,
    newOwnerName: string
  ): Promise<void> => {
    const res = await axiosInstance.put(
      baseUrl +
        `/archiveFirearm?firearmID=${id}&reason=${reason}&sap13Number=${SAP13Number}&casNumber=${CASNumber}&newOwnerName=${newOwnerName}`
    );
    return Promise.resolve(res.data);
  };

  const restoreFirearm = async (id: number): Promise<void> => {
    const res = await axiosInstance.put(
      baseUrl + `/restoreFirearm?firearmID=${id}`
    );
    return Promise.resolve(res.data);
  };

  const DownLoadFirearmFile = async (id: number): Promise<Firearm[]> => {
    const res = await axiosInstance.get(baseUrl + `/downLoadFile?id=${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "image/jpeg" + "application/pdf" + "image/png",
      },
      responseType: "blob",
    });
    return Promise.resolve(res.data);
  };

  const DownLoadFirearmImage = async (id: number): Promise<Firearm[]> => {
    const res = await axiosInstance.get(
      baseUrl + `/downLoadFirearmImage?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const DownLoadFirearmRenewedFile = async (id: number): Promise<Firearm[]> => {
    const res = await axiosInstance.get(
      baseUrl + `/downLoadFirearmRenewedFile?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const importFirearm = async (data: FormData): Promise<void> => {
    const res = await axiosInstance.put(baseUrl + `/importFirearms`, data);
    return Promise.resolve(res.data);
  };
  const checkFirearmAvailability = async (
    firearmID: number,
    securityFirmID: number,
    initDate: string,
    initTime: string,
    termDate: string,
    termTime: string
  ): Promise<void> => {
    const res = await axiosInstance.get(
      `/api/permitsAPI/checkFirearmAssignment?firearmID=${firearmID}&securityFirmID=${securityFirmID}&startDate=${initDate} ${initTime}&endDate=${termDate} ${termTime}`
    );
    console.log(
      "Firearm availability",
      `/api/permitsAPI/checkFirearmAssignment?firearmID=${firearmID}&securityFirmID=${securityFirmID}&startDate=${initDate} ${initTime}&endDate=${termDate} ${termTime}`,
      res.data
    );
    return Promise.resolve(res.data);
  };

  return {
    getFirearmByIDAsync,
    getAllFirearms,
    addFirearm,
    editFirearm,
    deleteFirearm,
    archiveFirearm,
    validateFirearm,
    restoreFirearm,
    getFirearmByIDAsyncForIncident,
    checkFirearmAvailability,
    DownLoadFirearmFile,
    DownLoadFirearmImage,
    DownLoadFirearmRenewedFile,
    importFirearm,
  };
};
const temp = Firearms();
export default temp;
