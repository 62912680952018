
import { Component, Vue, Prop, PropSync, Watch } from "vue-property-decorator";
import { AROs } from "@/hooks";
import { AroResponseCounts } from "models";
import AuthModule from "@/store/modules/Auth";

@Component({})
export default class manageAROModal extends Vue {
  @Prop() counts!: AroResponseCounts;
  @PropSync("open") manageItem!: boolean;
  @PropSync("refresh") refreshData!: boolean;
  @Prop() readonly data!: { type: boolean; field: any }; // Passed by reference ? // reportsToUser: [{ name: string; value: string }];
  valid = false;
  redacted = false;
  picture: File | Blob | null = null;
  signature: File | Blob | null = null;
  isArmResponseOfficer = true;
  password: string | null = "";
  confirmPassword: string | null = "";
  get aroRules() {
    const rules = {
      limit: (v: boolean) => {
        if (v == true) {
          if (
            this.getLimits &&
            this.counts.aroCount < this.getLimits.aroLimit
          ) {
            return true;
          }
        } else {
          return true;
        }
        return false;
      },
    };
    return [rules.limit];
  }
  get soRules() {
    const rules = {
      limit: (v: boolean) => {
        if (v == true) {
          if (this.getLimits && this.counts.soCount < this.getLimits.soLimit) {
            return true;
          }
        } else {
          return true;
        }
        return false;
      },
    };
    return [rules.limit];
  }
  get getLimits() {
    return AuthModule.getUserLimits;
  }
  rules = {
    required: (value: string): boolean | string =>
      !!value || "This field is Required.",
    uppercase: (value: string): boolean | string => {
      const pattern = /^(?=.*[A-Z])/;
      return (
        (value ? pattern.test(value) : true) ||
        "Requires atleast 1 uppercase letter"
      );
    },
    lowercase: (value: string): boolean | string => {
      const pattern = /^(?=.*[a-z])/;
      return (
        (value ? pattern.test(value) : true) ||
        "Requires atleast 1 lowercase letter"
      );
    },
    digit: (value: string): boolean | string => {
      const pattern = /^(?=.*\d)/;
      return (value ? pattern.test(value) : true) || "Requires atleast 1 digit";
    },
    special: (value: string): boolean | string => {
      const pattern = /^(?=.*\W)/;
      return (
        (value ? pattern.test(value) : true) ||
        "Requires atleast 1 special character"
      );
    },
    id: (value: string): boolean | string =>
      (value ? value.length == 13 && /^(?!^0*$)/.test(value) : true) ||
      "Requires a valid 13 digit ID number",
    cell: (value: string): boolean | string =>
      (value ? value.length == 10 : true) || "Requires 10 digits",
    email: (value: string): boolean | string => {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Invalid e-mail.";
    },
    // confirmPassword: (value: string): boolean | string =>
    //   (value && this.password === value) ||
    //   "Please confirm your password",
    confirmPassword: (value: string): boolean | string =>
      this.passwordConfirmation(value) ||
      `Passwords do not match ${this.password}`,
    fileSize: (value: { size: number }): boolean | string =>
      !value || value.size < 50000000 || "Max File Size is 50MB",
  };

  // @Watch("data", { immediate: true })
  // init(): void {
  //   HTMLFormControlsCollection.log("Initialize form")
  //   this.form = { ...this.data } as AROViewModel;
  //   (this.$refs.form as HTMLFormElement).validate;
  // }

  /**
   * https://stackoverflow.com/a/57056501
   */
  // @Watch("password")
  // async passwordConfirmation(): Promise<void> {
  //   console.log("Password Confirmation", this.$refs);
  //   try {
  //     await this.$nextTick();
  //     (this.$refs["passwordConfirmationInput"] as HTMLFormElement).validate();
  //   } catch (err) {
  //     console.log("Error", err);
  //   }
  // }
  @Watch("password")
  @Watch("confirmPassword")
  passwordConfirmation(pass: string): boolean {
    if (pass == this.password) {
      return true;
    } else {
      return false;
    }
  }

  @Watch("data.field.isSecurityGuard", { immediate: true })
  onisSecurityGaurdChange(): void {
    if (this.data.field.isSecurityGuard == true) {
      this.isArmResponseOfficer = false;
      this.data.field.isSecurityGuard = true;
    } else {
      this.isArmResponseOfficer = true;
      this.data.field.isSecurityGuard == true;
    }
  }

  closeModal(): void {
    this.password = "";
    this.confirmPassword = "";
    this.picture = null;
    this.signature = null;
    this.manageItem = false;
    (this.$refs.form as HTMLFormElement).reset();
  }

  @Watch("data.field", { immediate: true })
  modalDataChanged(): void {
    console.log("modal fields:", this.data.field);
  }

  async submit(): Promise<void> {
    console.log("Add user info: ", this.data.field);

    var formdata = new FormData();
    Object.keys(this.data.field).forEach((elem) => {
      formdata.append(elem, this.data.field[elem]);
    });
    if (
      this.password &&
      this.confirmPassword &&
      this.password == this.confirmPassword
    ) {
      formdata.append("password", this.password);
      formdata.append("confirmPassword", this.confirmPassword);
    }
    formdata.append("emailAddress", this.data.field.email.trim());
    formdata.append("picture", this.picture ? this.picture : "");
    formdata.append("signature", this.signature ? this.signature : "");
    formdata.append(
      "isSG",
      String(this.data.field.isSecurityGuard == true ? true : false)
    );

    try {
      const res = await AROs.addARO(formdata);
      this.$notifCreator.createSuccessNotification("ARO Added Succesfully!");

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.closeModal();
      this.refreshData = true;
    }
  }
}
