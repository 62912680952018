
import { Component, Vue } from "vue-property-decorator";
import GlobalNotifications from "@/components/notifications/GlobalNotifications.vue";
import OfflineFallback from "@/components/OfflineFallback.vue";
import { ConnectionStatus, Network } from "@capacitor/network";
import dayjs from "dayjs";
import fbHook from "./hooks/firebase";
import AuthModule from "@/store/modules/Auth";
import { Device } from "@capacitor/device";
import {
  Storage as StorageLib,
  Notifications as NotificationLib,
} from "@/hooks";
import {
  PushNotifications,
  Token,
  PushNotificationSchema,
  ActionPerformed,
} from "@capacitor/push-notifications";
import NotificationSystem from "./store/modules/NotificationSystem";
import { UserDetails } from "models";
import updateNotif from "@/components/pwa/updateAvailNotif.vue";
import InstallBanner from "@/components/pwa/InstallBanner.vue";
import { axiosInstance } from "@/plugins/axios";
// Network.addListener("networkStatusChange", (status) => {
//   console.log("Tits man.... changed", status);
// });
@Component({
  components: {
    OfflineFallback,
    GlobalNotifications,
    updateNotif,
    InstallBanner,
  },
})
export default class App extends Vue {
  get isOnline(): ConnectionStatus {
    return this.onLine;
  }
  get getVersion() {
    return process.env.VUE_APP_VERSION;
  }
  onLine: ConnectionStatus = {
    connected: false,
    connectionType: "unknown",
  };
  deferredPrompt: Event | null = null;
  showIos = true;
  async mounted(): Promise<void> {
    Network.addListener("networkStatusChange", (status) => {
      this.onLine = status;
      console.log("Network status changed", status);
    });
    this.onLine = await Network.getStatus();
    this.$vuetify.theme.dark = false;

    const devInfo = await Device.getInfo();
    const id = await Device.getId();
    const token = await this.getPWAToken();
    //checks to see if pwa is installed
    /*  const media = window.matchMedia("(display-mode: standalone)").matches;
    const andref = document.referrer.includes("android-app://"); */

    if (token != null) {
      await StorageLib.setFcmToken(token);
      NotificationSystem.setFirebaseToken(token);
    }

    /* console.log("media: ", media);
    console.log("andref: ", andref); */
    if (this.getProfile != null /*  && (media == true || andref == true) */) {
      /* console.log("token w/ getProfile: ", token); */
      if (token != null) {
        console.log(
          "Firebase Token: ",
          token,
          "devInfo: ",
          devInfo,
          "deviceId: ",
          id
        );
        await NotificationLib.registerFcmOnServer(token, devInfo, id);
      }
    }
  }

  get getProfile(): UserDetails | null {
    console.log("getUser: ", AuthModule.getUser);
    return AuthModule.getUser;
  }

  getYear(): number {
    return dayjs().year();
  }

  async created() {
    const devInfo = await Device.getInfo();

    if (devInfo.platform == "android" || devInfo.platform == "ios") {
      console.log("OS: ", devInfo.osVersion);
      // const temp = Number(devInfo.osVersion);
      // if (devInfo.osVersion && Number.isNaN(temp) == false && temp >= 11) {
      //   console.log("Foreground Notifications hid on: ", devInfo.osVersion);
      // }
      this.addListeners();
    }
  }

  async addListeners() {
    await PushNotifications.addListener("registration", async (token) => {
      console.info("Registration token: ", token.value, AuthModule.getUser);
      await StorageLib.setFcmToken(token.value);
      NotificationSystem.setFirebaseToken(token.value);
      //set token in local storage/vuex
      // if user doPushNotifications
      console.info("Registration token after: ", AuthModule.getUser);
      if (AuthModule.getUser != null) {
        console.log("doPushNotif parent block");
        await NotificationLib.doPushNotifications(token.value);
      } else {
        // this should not happen though test
        NotificationLib.createWarningNotification(
          `Does not register after getProfile. ${token.value}`
        );
      }
    });

    await PushNotifications.addListener("registrationError", (err) => {
      console.error("Registration error: ", err.error);
      NotificationLib.createErrorNotification(
        "Mobile Push notification registration failed. Contact the dev team."
      );
    });
    // Show us the notification payload if the app is open on our device
    await PushNotifications.addListener(
      "pushNotificationReceived",
      async (notification: PushNotificationSchema) => {
        console.log(
          "Notification in foreground: ",
          JSON.stringify(notification)
        );
        // vibrate

        // create notification based on X Y Z
        const dev = await Device.getInfo();
        if (dev) {
          if (dev.platform == "android") {
            console.log("Android 11 and up", dev.osVersion);
            const temp = Number(dev.osVersion);
            if (dev.osVersion && Number.isNaN(temp) == false && temp >= 11) {
              return Promise.resolve();
            }
          }
        }

        return Promise.resolve();
      }
    );
    // Method called when tapping on a notification
    await PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (notification: ActionPerformed) => {
        console.log(
          "Push notification action performed",
          notification.actionId,
          notification.inputValue
        );
      }
    );
  }

  async getPWAToken(): Promise<string | null> {
    const temp = await navigator.serviceWorker.getRegistration();
    var token: string | null = null;

    console.log("temp: ", temp);
    if (temp) {
      console.log("Requesting permission...");
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        console.log("Notification permission granted.");
        fbHook.initApp();
        token = await fbHook.getTokenAsync(temp);
      } else {
        console.log("Notification permission denied.");
        token = null;
      }
    } else {
      console.log("No registration for service worker");
      token = null;
    }
    return Promise.resolve(token);
  }
}
