
import { Component, Vue, Watch } from "vue-property-decorator";
import AuthModule from "@/store/modules/Auth";
import Dash from "@/store/modules/Dash";
import { Storage } from "@/hooks";

@Component
export default class AppBar extends Vue {
  title = "";
  flipDrawer(): void {
    // console.log("Flip drawer  to: ", !this.appDrawer);
    Dash.setAppDrawer(!this.appDrawer);
  }
  get getUsername(): string | null {
    return AuthModule.getUsername;
  }
  get appDrawer(): boolean {
    return Dash.getAppDrawer;
  }
  async logout() {
    // console.log("Logged out");
    AuthModule.removeLoggedIn();
    await Storage.removeLogin();
    // IndexedDB.unmountDB();
    this.$router.push({
      name: "Login",
    });
  }
  @Watch("$route.name", { immediate: true })
  routeChanged(newTitle: string): void {
    switch (newTitle) {
      case "dash.myPermits":
        this.title = "My Permits";
        break;
      default:
        this.title = newTitle;
        break;
    }
  }
  get getUserRoles(): string {
    let temp = "";
    AuthModule.getUserRoles.forEach((e) => {
      temp += e.roleName + ",";
    });
    if (temp.length > 2) {
      const lastChar = temp.charAt(temp.length - 1);
      // console.log("Last char: ", lastChar);
      if (lastChar === ",") {
        // console.log("Slice char: ", lastChar);
        temp = temp.slice(0, temp.length - 1);
      }
    }
    return temp;
  }
}
