
import { Component, Vue, Watch } from "vue-property-decorator";
import {
  User,
  CRUDFunctionality,
  Documents,
  Downloader,
  Subscription,
  Authenticate,
} from "@/hooks";
import SubscriptionModule from "@/store/modules/Subscription";
import { ManageUserViewModel } from "models";
import JsonExcel from "@/components/JsonExcel.vue";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import ManageUserModal from "@/views/manage/users/UsersAddEdit.vue";
import { ROLE as RoleEnum } from "@/enums/roles";
import { DataTableHeader } from "vuetify";
import dayjs from "dayjs";
import AuthModule from "@/store/modules/Auth";
import { updateSubscriptionRequest } from "subscriptionModels";

@Component({
  components: {
    JsonExcel,
    ManageUserModal,
  },
  filters: {
    fileName: function (value: string) {
      if (value.length > 30) {
        const front = value.substring(0, 20);
        const end = value.substring(value.length - 10);
        return front + "..." + end;
      } else {
        return value;
      }
    },
  },
})
export default class ManageUsers extends Vue {
  users: ManageUserViewModel[] = [];
  newUsers = 1;
  limitedReachedContract = false;
  limitedReachedPayfast = false;
  adjustAdditionalUsers = {
    open: false,
    amount: 0,
  };
  openAdjustAdditional() {
    if (this.getUserLimit == null) {
      return;
    }
    this.adjustAdditionalUsers.amount =
      this.userCounts.usersCount - this.getUserLimit.userLimit;
    this.adjustAdditionalUsers.open = true;
  }
  userCounts: {
    usersCount: number;
  } = {
    usersCount: 0,
  };
  get getDisplayLimit() {
    if (this.getUserLimit == null) return "NA";
    return this.getUserLimit.userLimit + this.getAdditonal;
  }
  get getAdditonal() {
    if (
      AuthModule.getUserOverview == null ||
      AuthModule.getUserOverview.securityFirm.additionalUsers == null
    )
      return 0;
    return AuthModule.getUserOverview.securityFirm.additionalUsers;
  }
  get newMonthly() {
    if (this.getUserLimit == null || this.getSubscription == null) {
      return "N/A";
    }
    const currentUsers =
      this.userCounts.usersCount > this.getUserLimit.userLimit
        ? this.getUserLimit.userLimit
        : this.userCounts.usersCount;
    const currentAdditionalUsers =
      this.userCounts.usersCount > this.getUserLimit.userLimit
        ? this.userCounts.usersCount - this.getUserLimit.userLimit
        : 0;
    const baseCost = this.getSubscription.defaultCostPerUser * currentUsers;
    const addCost =
      this.getSubscription.additionalUsersAmount * currentAdditionalUsers;
    const extraCost =
      this.adjustAdditionalUsers.amount *
      this.getSubscription.additionalUsersAmount;
    console.log(
      "Money :",
      baseCost,
      addCost,
      extraCost,
      currentUsers,
      currentAdditionalUsers
    );
    const amount = baseCost + addCost + extraCost;
    return "R" + amount;
    // return (
    //   this.getSubscription.additionalUsersAmount * this.newUsers +
    //   this.getSubscription.defaultCostPerUser * this.userCounts.usersCount +
    //   this.getSubscription.additionalUsersAmount *
    //     (AuthModule.getUserOverview?.securityFirm.additionalUsers
    //       ? AuthModule.getUserOverview.securityFirm.additionalUsers
    //       : 0)
    // );
  }
  get getUserLimit() {
    return AuthModule.getUserLimits;
  }
  usersFiltered: ManageUserViewModel[] = [];
  loading = false;
  search = "";
  sortDesc = false;
  sortBy = "name";
  expanded = [];
  deleteModal = false;
  manageUser = false;
  deleteUserName = "";
  manageUserId = 0;
  refresh = false;
  // reportsToUser = [
  //   {
  //     name: "",
  //     value: 0,
  //   },
  // ];
  modalData = {
    type: " ",
    field: {},
    // reportsToUser: this.reportsToUser,
  };
  rules = {
    required: (value: string): boolean | string =>
      !!value || "This field is Required.",
    uppercase: (value: string): boolean | string => {
      const pattern = /^(?=.*[A-Z])/;
      return pattern.test(value) || "Requires atleast 1 uppercase letter";
    },
    lowercase: (value: string): boolean | string => {
      const pattern = /^(?=.*[a-z])/;
      return pattern.test(value) || "Requires atleast 1 lowercase letter";
    },
    digit: (value: string): boolean | string => {
      const pattern = /^(?=.*\d)/;
      return pattern.test(value) || "Requires atleast 1 digit";
    },
    special: (value: string): boolean | string => {
      const pattern = /^(?=.*\W)/;
      return pattern.test(value) || "Requires atleast 1 special character";
    },
    id: (value: string): boolean | string =>
      value.length == 13 || "Requires 13 digits",
    cell: (value: string): boolean | string =>
      value.length == 10 || "Requires 13 digits",
    email: (value: string): boolean | string => {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Invalid e-mail.";
    },
  };
  get getSubscription() {
    if (AuthModule.getUserOverview == null) return null;
    return AuthModule.getUserOverview.securityFirm.securityFirmSubscription;
  }
  headers: DataTableHeader[] = [
    { text: "", value: "actions", sortable: false },
    { text: "First Name", value: "name" },
    { text: "Last Name", value: "surname" },
    { text: "Job Title", value: "jobTitle" },
    { text: "Level of Access", value: "role" },
    { text: "", value: "data-table-expand" },
  ];
  expReports: {
    "First Name": string;
    "Last Name": string;
    "Job Title": string;
    "Level of Access": string;
    Email: string;
    "Contact No": string;
    Picture: string;
    Signature: string;
    "Is ARO/SO": boolean;
    "Assign Firearm (Full Access)": boolean;
    "Incident (Full Access)": boolean;
    "Reporting (Full Access)": boolean;
    "Is User Locked Out": boolean;
  }[] = [];
  expFields = {
    "First Name": "name",
    "Last Name": "surname",
    "Job Title": "jobTitle",
    "Level of Access": "role",
    Email: "email",
    "Contact No": "contactNumber",
    Picture: " ",
    Signature: " ",
    "Is ARO/SO": "isArmResponseOfficer",
    "Assign Firearm (Full Access)": "assignFirearmProxy",
    "Incident (Full Access)": "incidentProxy",
    "Reporting (Full Access)": "reportingProxy",
    "Is User Locked Out": "lockoutEnabled",
  };

  shouldDisplayForRole(name: string | undefined): boolean {
    const has = CRUDFunctionality.hasAccess(name);
    //console.log("Name: ", name, has);
    return has;
  }

  @Watch("refresh")
  async modalDataChanged() {
    console.log("triggered");
    if (this.refresh == true) {
      await this.init();
      // if (AuthModule.getUserRoles.find((e) => e.roleId == 2) != null) {
      //   await Subscription.getSubscription();
      // }
    }
  }

  openEditUser(i: ManageUserViewModel): void {
    this.modalData.type = "edit";
    console.log("User Profile", i);
    this.modalData.field = i;
    // this.modalData.reportsToUser = this.reportsToUser; // Removed temporarily
    this.refresh = false;
    this.manageUser = true;
  }

  openAddUser(): void {
    if (this.getUserLimit == null) {
      console.error("No user limit");
      return;
    }
    if (
      this.getUserLimit.userLimit + this.getAdditonal <=
      this.userCounts.usersCount
    ) {
      console.warn("You have reached max users");
      if (
        AuthModule.getUserOverview &&
        AuthModule.getUserOverview.securityFirm
      ) {
        if (AuthModule.getUserOverview.securityFirm.payFastPass == true) {
          this.limitedReachedContract = true;
        } else {
          if (
            AuthModule.getUserOverview.securityFirm.securityFirmSubscription !=
            null
          ) {
            // console
            this.openAdjustAdditional();
          } else {
            alert("Having issue calculating limits");
          }
        }
      }
      // upgradeSubscription =
      // open console popup;
      return;
    } else {
      this.modalData.type = "add";
      this.modalData.field = {};
      // this.modalData.reportsToUser = this.reportsToUser;
      this.refresh = false;
      this.manageUser = true;
    }
    // if (this.getSubscription) {
    //   if (
    //     this.getSubscription.userCount + 1 >
    //     this.getSubscription.numberOfUsersAllowed
    //   ) {
    //     console.log("Maxed out");
    //   }
    // }
  }

  openDeleteUser(i: ManageUserViewModel): void {
    this.deleteUserName = i.name + " " + i.surname;
    this.manageUserId = i.id;
    this.deleteModal = true;
  }

  async deleteUser(): Promise<void> {
    try {
      const res = await User.deleteUser(this.manageUserId.toString());
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.deleteModal = false;
      this.init();
    }
  }
  updateReportsFiltered(event: any) {
    this.usersFiltered = event ? (event as any[]) : this.users;
  }
  get getReportsFiltered() {
    return this.populateExportData(this.usersFiltered);
  }
  async generatePDF(): Promise<void> {
    try {
      const columns = [
        { header: "First Name", dataKey: "First Name" },
        { header: "Last Name", dataKey: "Last Name" },
        { header: "Job Title", dataKey: "Job Title" },
        { header: "Level of Access", dataKey: "Level of Access" },
        { header: "Email", dataKey: "Email" },
        { header: "Contact No", dataKey: "Contact No" },
        { header: "Picture", dataKey: "Picture" },
        { header: "Signature", dataKey: "Signature" },
        { header: "Is ARO/SO", dataKey: "Is ARO/SO" },
        {
          header: "Assign Firearm (Full Access)",
          dataKey: "Assign Firearm (Full Access)",
        },
        { header: "Incident (Full Access)", dataKey: "Incident (Full Access)" },
        {
          header: "Reporting (Full Access)",
          dataKey: "Reporting (Full Access)",
        },
        { header: "Is User Locked Out", dataKey: "Is User Locked Out" },
      ];

      await Downloader.generatePdfAndSave({
        fileName: `Users (${dayjs().format("YYYY-MM-DD")})`,
        columns: columns,
        body: this.expReports,
        horizontalPageBreakRepeat: "First Name",
      });
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async mounted(): Promise<void> {
    await this.getUsers();
    // this.reportsToUser = [];
    // this.users.forEach((elem) =>
    //   this.reportsToUser.push({
    //     name: elem.name + " " + elem.surname,
    //     value: elem.id,
    //   })
    // );
  }
  loadingPayfast = false;
  async upgradePayfast() {
    try {
      this.loadingPayfast = true;
      const sub =
        AuthModule.getUserOverview?.securityFirm.securityFirmSubscription;
      if (sub) {
        const val: updateSubscriptionRequest = {
          additionalUsers: this.adjustAdditionalUsers.open
            ? this.adjustAdditionalUsers.amount
            : this.getAdditonal + this.newUsers,
          subscriptionId: sub.id,
        };
        await Subscription.updateSubscription(val);
        await this.init();
        if (this.adjustAdditionalUsers.open) {
          this.adjustAdditionalUsers.open = false;
        } else this.limitedReachedPayfast = false;
      }
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingPayfast = false;
    }
  }
  async init() {
    await Authenticate.getUser();
    await this.getUsers();
  }

  async getUsers(): Promise<void> {
    try {
      this.loading = true;
      const res = await User.getUsers();
      const response: ManageUserViewModel[] = [];
      if (res) {
        res.manageUsers.forEach((elem: ManageUserViewModel) => {
          if (elem.roleID != 6) {
            response.push(elem);
          }
        });
      }

      this.users = response;
      this.userCounts = res.userCounts;

      return Promise.resolve();
    } catch (err) {
      this.loading = false;
      return Promise.reject(err);
    } finally {
      this.loading = false;
      this.expReports = this.populateExportData(this.users);
      this.getUserSignatureByID();
      this.getUserPicturesByID();
      // console.log("Users:", this.users);
    }
  }

  getUserSignatureByID(): void {
    this.expanded = [];
    this.users.forEach(async (elem) => {
      if (elem.userSignatureID != null) {
        try {
          const res = await User.getUserSignatureByID(elem.userSignatureID);
          elem.signature = res;
          return Promise.resolve();
        } catch (err) {
          return Promise.reject(err);
        }
      }
    });
  }

  getUserPicturesByID(): void {
    this.expanded = [];
    this.users.forEach(async (elem) => {
      if (elem.userPictureID != null) {
        try {
          const res = await User.getUserPicturesByID(elem.userPictureID);
          elem.image = res;
          return Promise.resolve();
        } catch (err) {
          return Promise.reject(err);
        }
      }
    });
  }

  populateExportData(items: any[]) {
    return items.map((elem: any) => {
      return {
        "First Name": elem.name != null ? elem.name : " ",
        "Last Name": elem.surname != null ? elem.surname : " ",
        "Job Title": elem.jobTitle != null ? elem.jobTitle : " ",
        "Level of Access": elem.roleName != null ? elem.roleName : " ",
        Email: elem.email != null ? elem.email : " ",
        "Contact No": elem.contactNumber != null ? elem.contactNumber : " ",
        Picture: " ",
        Signature: " ",
        "Is ARO/SO":
          elem.isArmResponseOfficer != null ? elem.isArmResponseOfficer : " ",
        "Assign Firearm (Full Access)":
          elem.assignFirearmProxy != null ? elem.assignFirearmProxy : " ",
        "Incident (Full Access)":
          elem.incidentProxy != null ? elem.incidentProxy : " ",
        "Reporting (Full Access)":
          elem.reportingProxy != null ? elem.reportingProxy : " ",
        "Is User Locked Out":
          elem.lockoutEnabled == null || elem.lockoutEnabled == undefined
            ? false
            : elem.lockoutEnabled,
      };
    });
  }

  async downloadwPath(i: any): Promise<void> {
    var ext = i.substr(i.lastIndexOf(".") + 1);
    var type = "image/jpeg";
    if (ext == "jpeg" || ext == "jpg") {
      type = "image/jpeg";
    } else if (ext == "pdf") {
      type = "application/pdf";
    } else if (ext == "png") {
      type = "image/png";
    }
    try {
      await Documents.getFile(i).then((response: any) => {
        const url = window.URL.createObjectURL(
          new Blob([response], {
            type: type,
          })
        );
        window.open(url);
        console.log(response);
      });
    } catch (err) {
      const req = await require("@/assets/FileNotFound.png");
      const resO = await fetch(req);
      const img = await resO.blob();
      const url = URL.createObjectURL(img);
      window.open(url);
      this.$notifCreator.createErrorNotification("Can't find Image.");
      return Promise.reject(err);
    }
  }

  async download(i: any, type: string): Promise<void> {
    if (i.mimeType == null) {
      this.$notifCreator.createErrorNotification("Can't find Image.");
    } else {
      try {
        if (type == "signature") {
          await User.downloadUserFile(i.id).then((response: any) => {
            const url = URL.createObjectURL(
              new Blob([response], {
                type:
                  response.type == "application/octet-stream"
                    ? "image/png"
                    : response.type,
              })
            );
            window.open(url);
            console.log(response);
          });
        } else if (type == "image") {
          await User.downloadUserPicture(i.id).then((response: any) => {
            const url = URL.createObjectURL(
              new Blob([response], {
                type:
                  response.type == "application/octet-stream"
                    ? "image/png"
                    : response.type,
              })
            );
            window.open(url);
            console.log(response.type);
          });
        }
      } catch (err) {
        this.$notifCreator.createErrorNotification("Can't find Image.");
        return Promise.reject(err);
      }
    }
  }
}
