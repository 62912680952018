import { Device } from "@capacitor/device";
import { Filesystem, Directory, Encoding } from "@capacitor/filesystem";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { Notifications } from "./Notifications";

const Downloader = () => {
  const saveFile = async (base64: string, filename: string, mime: string) => {
    // console.log("Blob ", blob);
    const device = (await Device.getInfo()).platform;
    try {
      if (device == "web") {
        const a = document.createElement("a"); //Create <a>
        a.href = base64; //Image Base64 Goes here
        a.download = filename; //File name Here
        a.click(); //Downloaded file
      } else {
        console.log("Filename ", filename);
        console.log("Mime ", mime);
        const temp2 = await Filesystem.requestPermissions();
        if (temp2.publicStorage == "granted") {
          console.log("Saving file... ", filename);
          const res = await Filesystem.writeFile({
            path: `zeroeight/${filename}`,
            recursive: true,
            data: base64,
            directory: Directory.Documents,
          });
          console.log("Finished saving file... ", filename, res);
        }

        console.log("Save file res : ", temp2);
      }
    } catch (err) {
      Notifications.createErrorNotification(err as string);
    }
  };

  const generatePdfAndSave = async (table: {
    fileName: string;
    columns: { header: string; dataKey: string }[];
    body: any[];
    horizontalPageBreakRepeat?: string;
  }) => {
    // Text obj { name, x, y }
    // path
    // autotable { columns, horizontalPageBreakRepeat}

    const doc = new jsPDF({
      orientation: "l",
      compress: true,
    });
    // doc.text(text.name, text.x, text.y);
    const textWidth =
      (doc.getStringUnitWidth(table.fileName) * doc.getFontSize()) /
      doc.internal.scaleFactor;
    const textOffset = (doc.internal.pageSize.width - textWidth) / 2;
    doc.text(table.fileName, textOffset, 12);
    const img = new Image();
    img.src = require("@/assets/logoPWAtrans.png");
    doc.addImage(img, "png", 120, 3, 50, 50, undefined, "FAST");
    autoTable(doc, {
      /* head: head, */
      body: table.body,
      columns: table.columns,
      startY: 45,
      horizontalPageBreak: true,
      horizontalPageBreakRepeat: table.horizontalPageBreakRepeat,
      // didDrawCell: (data) => {
      //   console.log(data.column.index);
      // },
    });

    const devInfo = await Device.getInfo();
    if (devInfo.platform == "web") {
      doc.save(`${table.fileName}.pdf`);
    } else {
      try {
        const output = doc.output("datauristring");
        console.log("Output for pdf: ", output);
        if (typeof output == "string") {
          await saveFile(output, "ActivityLog.pdf", "application/pdf");
        }
        return Promise.resolve();
      } catch (err) {
        console.log(
          "Error: ",
          JSON.stringify(err, ["message", "arguments", "type", "name"])
        );
        return Promise.reject(err);
      }
    }
  };
  return {
    generatePdfAndSave,
    saveFile,
  };
};

const temp = Downloader();

export default temp;
