
import { Component, Vue } from "vue-property-decorator";
import AuthModule from "@/store/modules/Auth";
import { IndexedDB, Authenticate, Subscription } from "@/hooks";

@Component
export default class Dashboard extends Vue {
  get getUsername(): string | null {
    return AuthModule.getUsername;
  }
  async created(): Promise<void> {
    if (this.getUsername != null) {
      const db = await IndexedDB.getDB(this.getUsername).catch((err) => {
        this.$notifCreator.createErrorNotification(err.toString());
      });
    }
  }
  async beforeMount() {
    try {
      console.log("Trust");
      await Authenticate.getRoles();
      await Authenticate.getUser();
      // if (AuthModule.getUserRoles.find((e) => e.roleId == 2) != null) {
      //   await Subscription.getSubscription();
      // }
    } catch (err) {
      console.error("error ", err);
      return Promise.reject(err);
    }
    // setTimeout(() => {
    //   this.$notifCreator.createSuccessNotification("Example of System Notification");
    // }, 100);
    // setTimeout(() => {
    //   this.$notifCreator.createErrorNotification("Error me");
    // }, 1000);
    // setTimeout(() => {
    //   this.$notifCreator.createWarningNotification("Warning Me");
    // }, 1500);
    // setTimeout(() => {
    //   this.$notifCreator.createSuccessNotification("Success Me");
    // }, 2000);
  }

  beforeUnmount(): void {
    IndexedDB.unmountDB();
  }
}
