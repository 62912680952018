var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"px-0",attrs:{"cols":"12"}},[[_vm._t("default",null,{"goto":_vm.goto,"currentStep":_vm.currentStep})]],2)],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"auto"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"success"}},[_vm._v("mdi-circle")]),_c('span',[_vm._v(" Contains atleast 1 document")])],1),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"auto"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"error"}},[_vm._v("mdi-circle")]),_c('span',[_vm._v(" Required to proceed")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"px-0"},[_c('v-stepper',{staticClass:"docStepper",attrs:{"vertical":"","flat":""},model:{value:(_vm.currentStep),callback:function ($$v) {_vm.currentStep=$$v},expression:"currentStep"}},[_c('v-toolbar',{attrs:{"color":"primary darken-1 white--text text-center text-h5"}},[_vm._v(" Compliance Documents ")]),_c('v-stepper-step',{ref:"mod-step-1",staticClass:"primary black--text",class:{
              completed: _vm.getPsiraCompleted,
            },attrs:{"step":1,"editable":"","color":_vm.getPsiraCompleted == true ? 'success' : 'error'},on:{"click":function($event){return _vm.onClick($event, 1)}}},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":"","justify":"space-between","align":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('span',{staticClass:"white--text"},[_vm._v("PSIRA Registration")])]),(_vm.currentStep == 1 && _vm.getPsiraCompleted == true)?_c('v-col',{staticClass:"text-end",attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":function($event){return _vm.deleteFile()}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1):_vm._e(),(_vm.getCompletedBreakdown.urgent.psiraDocs.length > 0)?_c('v-col',{attrs:{"cols":"1"}},[_c('v-chip',{staticClass:"white--text",attrs:{"color":"error"}},[_vm._v("Urgent "+_vm._s(_vm.getCompletedBreakdown.urgent.psiraDocs.length))])],1):_vm._e()],1)],1)],1),_c('v-stepper-content',{staticClass:"ml-0",attrs:{"step":1}},[(_vm.getAroDetails != null)?[_c('PsiraReg',{on:{"refresh":_vm.refreshDocs}})]:_vm._e()],2),_c('v-stepper-step',{ref:"mod-step-2",staticClass:"primary black--text",class:{
              completed: _vm.getKoaCompleted,
            },attrs:{"step":2,"editable":_vm.getPsiraCompleted || _vm.getKoaCompleted,"color":_vm.getKoaCompleted == true ? 'success' : 'error'},on:{"click":function($event){return _vm.onClick($event, 2)}}},[_c('v-row',{attrs:{"dense":"","justify":"space-between","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('span',{staticClass:"white--text"},[_vm._v("Knowledge of the Act")])]),(_vm.currentStep == 2 && _vm.getKoaCompleted == true)?_c('v-col',{staticClass:"text-end",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":function($event){return _vm.deleteFile()}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1):_vm._e()],1)],1),_c('v-stepper-content',{staticClass:"ml-0",attrs:{"step":2,"editable":""}},[(_vm.getAroDetails != null)?[_c('KnowledgeOfAct',{on:{"refresh":_vm.refreshDocs}})]:_vm._e()],2),_c('v-stepper-step',{ref:"mod-step-3",staticClass:"primary black--text",class:{
              completed: _vm.getBasicPusCompleted,
            },attrs:{"step":3,"editable":_vm.getKoaCompleted || _vm.getBasicPusCompleted,"color":_vm.getBasicPusCompleted == true ? 'success' : 'error'},on:{"click":function($event){_vm.onClick($event, 3),
                _vm.triggerEdit(
                  _vm.getAroDetails.basicProficiencyCertificates.filter(
                    (e) => e.isTacticalUnitStandards == true
                  ),
                  true
                )}}},[_c('v-row',{attrs:{"dense":"","justify":"space-between","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('span',{staticClass:"white--text"},[_vm._v("Basic Proficiency Unit Standards")])]),(_vm.currentStep == 3 && _vm.getBasicPusCompleted == true)?_c('v-col',{staticClass:"text-end",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":function($event){return _vm.deleteFile()}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1):_vm._e()],1)],1),_c('v-stepper-content',{staticClass:"ml-0",attrs:{"step":3,"editable":""}},[(_vm.getAroDetails != null)?[_c('ComplexDataIterator',{attrs:{"items":_vm.getAroDetails.basicProficiencyCertificates.filter(
                    (e) => e.isTacticalUnitStandards == false
                  ),"comp":"BasicPus"},on:{"refresh":_vm.refreshDocs}})]:_vm._e()],2),_c('v-stepper-step',{ref:"mod-step-4",staticClass:"primary black--text",class:{
              completed: _vm.getSapsCompleted,
            },attrs:{"step":4,"editable":_vm.getBasicPusCompleted || _vm.getSapsCompleted,"color":_vm.getSapsCompleted == true ? 'success' : 'error'},on:{"click":function($event){return _vm.onClick($event, 4)}}},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":"","justify":"space-between","align":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('span',{staticClass:"white--text"},[_vm._v("SAPS Competency Certificates")])]),(_vm.currentStep == 4 && _vm.getSapsCompleted == true)?_c('v-col',{staticClass:"text-end",attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":function($event){return _vm.deleteFile()}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1):_vm._e(),(
                    _vm.getCompletedBreakdown.urgent.sapsCompCerts.length > 0 &&
                    _vm.getCompletedBreakdown.urgent.sapsCompCerts.filter(
                      (e) => e.isRegulation21Certificate == false
                    ).length != 0
                  )?_c('v-col',{attrs:{"cols":"1"}},[_c('v-chip',{staticClass:"white--text",attrs:{"color":"error"}},[_vm._v("Urgent")])],1):_vm._e()],1)],1)],1),_c('v-stepper-content',{staticClass:"ml-0",attrs:{"step":4}},[(_vm.getAroDetails != null)?[_c('ComplexDataIterator',{attrs:{"items":_vm.getAroDetails.sapsCompetencyCertificates.filter(
                    (e) => e.isRegulation21Certificate == false
                  ),"comp":"SapsCompentency"},on:{"refresh":_vm.refreshDocs}})]:_vm._e()],2),_c('v-stepper-step',{ref:"mod-step-5",staticClass:"primary black--text",class:{
              completed: _vm.getBusinessPusCompleted,
            },attrs:{"editable":_vm.getSapsCompleted || _vm.getBusinessPusCompleted,"color":_vm.getBusinessPusCompleted == true ? 'success' : 'error',"step":5},on:{"click":function($event){return _vm.onClick($event, 5)}}},[_c('v-row',{attrs:{"dense":"","justify":"space-between","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('span',{staticClass:"white--text"},[_vm._v("Business Proficiency Unit Standards")])]),(_vm.currentStep == 5 && _vm.getBusinessPusCompleted == true)?_c('v-col',{staticClass:"text-end",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":function($event){return _vm.deleteFile()}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1):_vm._e()],1)],1),_c('v-stepper-content',{staticClass:"ml-0",attrs:{"step":5}},[(_vm.getAroDetails != null)?[_c('ComplexDataIterator',{attrs:{"items":_vm.getAroDetails.businessProficiencyCertificates,"comp":"BusinessPus"},on:{"refresh":_vm.refreshDocs}})]:_vm._e()],2),_c('v-stepper-step',{ref:"mod-step-6",staticClass:"primary black--text",class:{
              completed: _vm.getReg21Completed,
            },attrs:{"editable":_vm.getBusinessPusCompleted || _vm.getReg21Completed,"color":_vm.getReg21Completed == true ? 'success' : 'error',"step":6},on:{"click":function($event){return _vm.onClick($event, 6)}}},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":"","justify":"space-between","align":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('span',{staticClass:"white--text"},[_vm._v("Regulation 21")])]),(_vm.currentStep == 6 && _vm.getReg21Completed == true)?_c('v-col',{staticClass:"text-end",attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":function($event){return _vm.deleteFile()}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1):_vm._e(),(
                    _vm.getCompletedBreakdown.urgent.sapsCompCerts.length > 0 &&
                    _vm.getCompletedBreakdown.urgent.sapsCompCerts.filter(
                      (e) => e.isRegulation21Certificate == true
                    ).length != 0
                  )?_c('v-col',{attrs:{"cols":"1"}},[_c('v-chip',{staticClass:"white--text",attrs:{"color":"error"}},[_vm._v("Urgent")])],1):_vm._e()],1)],1)],1),_c('v-stepper-content',{staticClass:"ml-0",attrs:{"step":6}},[(_vm.getAroDetails != null)?[_c('ComplexDataIterator',{attrs:{"items":_vm.getAroDetails.sapsCompetencyCertificates.filter(
                    (e) => e.isRegulation21Certificate == true
                  ),"comp":"Regulation21"},on:{"refresh":_vm.refreshDocs}})]:_vm._e()],2)],1),(_vm.getAro != null)?_c('v-stepper',{staticClass:"docStepper optional",attrs:{"vertical":"","flat":""},model:{value:(_vm.currentStep),callback:function ($$v) {_vm.currentStep=$$v},expression:"currentStep"}},[_c('v-toolbar',{attrs:{"color":"primary darken-1 white--text text-center text-h5"}},[_c('v-toolbar-title',[_vm._v("Optional Documents "),_c('div',{staticClass:"text-caption text-left"},[_vm._v(" * Order Irrelevant ")])])],1),_c('v-stepper-step',{ref:"mod-step-7",staticClass:"primary black--text",class:{
              completed: _vm.getBasicPersonalDocsCompleted,
            },attrs:{"step":7,"editable":"","color":_vm.getBasicPersonalDocsCompleted == true ? 'success' : 'secondary'},on:{"click":function($event){return _vm.onClick($event, 7)}}},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":"","justify":"space-between","align":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('span',{staticClass:"white--text"},[_vm._v("Basic Personal Documents")])]),(
                    _vm.currentStep == 7 && _vm.getBasicPersonalDocsCompleted == true
                  )?_c('v-col',{staticClass:"text-end",attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":function($event){return _vm.deleteFile()}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1):_vm._e(),(_vm.getCompletedBreakdown.urgent.dls.length > 0)?_c('v-col',{attrs:{"cols":"1"}},[_c('v-chip',{staticClass:"white--text",attrs:{"color":"error"}},[_vm._v("Urgent")])],1):_vm._e()],1)],1)],1),_c('v-stepper-content',{staticClass:"ml-0",attrs:{"step":7}},[(_vm.getAroDetails != null)?[_c('BasicPersonalDocs',{on:{"refresh":_vm.refreshDocs}})]:_vm._e()],2),_c('v-stepper-step',{ref:"mod-step-8",staticClass:"primary black--text",class:{
              completed: _vm.getRangeOfficerCompleted,
            },attrs:{"step":8,"editable":_vm.canRangeOfficer,"color":_vm.getRangeOfficerCompleted == true ? 'success' : 'secondary'},on:{"click":function($event){return _vm.onClick($event, 8)}}},[_c('v-row',{attrs:{"dense":"","justify":"space-between","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('span',{staticClass:"white--text"},[_vm._v("Range Officer Unit Standards "+_vm._s(_vm.canRangeOfficer == true ? "" : "(disabled: requires business proficiencies)"))])]),(_vm.currentStep == 8 && _vm.getRangeOfficerCompleted == true)?_c('v-col',{staticClass:"text-end",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":function($event){return _vm.deleteFile()}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1):_vm._e()],1)],1),_c('v-stepper-content',{staticClass:"ml-0",attrs:{"step":8}},[(_vm.getAroDetails != null)?[_c('RangeOfficerUnitStandards',{attrs:{"certificate":_vm.getAroDetails.rangeOfficerUnitStandards,"aroId":_vm.getAroDetails.id},on:{"refresh":_vm.refreshDocs}})]:_vm._e()],2),_c('v-stepper-step',{ref:"mod-step-9",staticClass:"primary black--text",class:{
              completed: _vm.getTacticalKnowledgeCompleted,
            },attrs:{"step":9,"editable":"","color":_vm.getTacticalKnowledgeCompleted == true ? 'success' : 'secondary'},on:{"click":function($event){return _vm.onClick($event, 9)}}},[_c('v-row',{attrs:{"dense":"","justify":"space-between","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('span',{staticClass:"white--text"},[_vm._v("Tactical knowledge in the use of Firearms")])]),(_vm.currentStep == 9 && _vm.getTacticalKnowledgeCompleted == true)?_c('v-col',{staticClass:"text-end",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":function($event){return _vm.deleteFile()}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1):_vm._e()],1)],1),_c('v-stepper-content',{staticClass:"ml-0",attrs:{"step":9}},[(_vm.getAroDetails != null)?[_c('TacticalKnowledge',{attrs:{"certificate":_vm.getAroDetails.tacticalKnowledge[0],"aroId":_vm.getAroDetails.id},on:{"refresh":_vm.refreshDocs}})]:_vm._e()],2),_c('v-stepper-step',{ref:"mod-step-10",staticClass:"primary black--text",class:{
              completed: _vm.getTacticalUSCompleted,
            },attrs:{"step":10,"editable":"","color":_vm.getTacticalUSCompleted == true ? 'success' : 'secondary'},on:{"click":function($event){return _vm.onClick($event, 10)}}},[_c('v-row',{attrs:{"dense":"","justify":"space-between","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('span',{staticClass:"white--text"},[_vm._v("Tactical Unit Standards")])]),(_vm.currentStep == 10 && _vm.getTacticalUSCompleted == true)?_c('v-col',{staticClass:"text-end",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":function($event){return _vm.deleteFile()}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1):_vm._e()],1)],1),_c('v-stepper-content',{staticClass:"ml-0",attrs:{"step":10}},[(_vm.getAroDetails != null)?[_c('ComplexDataIterator',{attrs:{"items":_vm.getAroDetails.basicProficiencyCertificates.filter(
                    (e) => e.isTacticalUnitStandards == true
                  ),"comp":"TacticalUnitStandards"},on:{"refresh":_vm.refreshDocs}})]:_vm._e()],2),_c('v-stepper-step',{ref:"mod-step-11",staticClass:"primary black--text",class:{
              completed: _vm.getFirearmInstructorCompleted,
            },attrs:{"step":11,"editable":"","color":_vm.getFirearmInstructorCompleted == true ? 'success' : 'secondary'},on:{"click":function($event){return _vm.onClick($event, 11)}}},[_c('v-row',{attrs:{"dense":"","justify":"space-between","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('span',{staticClass:"white--text"},[_vm._v("Firearm Instructor Unit Standards")])]),(
                  _vm.currentStep == 11 && _vm.getFirearmInstructorCompleted == true
                )?_c('v-col',{staticClass:"text-end",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":function($event){return _vm.deleteFile()}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1):_vm._e()],1)],1),_c('v-stepper-content',{staticClass:"ml-0",attrs:{"step":11}},[(_vm.getAroDetails != null)?[_c('FirearmInstructorUnitStandards',{attrs:{"certificate":_vm.getAroDetails.instructorUnitStandards[0],"aroId":_vm.getAroDetails.id},on:{"refresh":_vm.refreshDocs}})]:_vm._e()],2)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }