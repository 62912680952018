import { Component, Vue } from "vue-property-decorator";
@Component
export default class updateMixin extends Vue {
  registration: ServiceWorkerRegistration | null = null;
  updateExists = false;
  refreshing = false;

  updateAvailable(event: CustomEvent) {
    // console.log("This event ", event);
    this.registration = event.detail;
    this.updateExists = true;
  }
  get getUpdateExists(): boolean {
    return this.updateExists;
  }
  created() {
    // console.log("Created Snackbar swUpdated");
    document.addEventListener(
      "swUpdated",
      ((event: CustomEvent) => {
        this.updateAvailable(event);
      }) as EventListener,
      { once: true }
    );
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing == true) return;
      this.refreshing = true;
      window.location.reload();
    });
  }

  refreshApp() {
    this.updateExists = false;
    // Make sure we only send a 'skip waiting' message if the SW is waiting
    if (!this.registration || !this.registration.waiting) return;
    // // Send message to SW to skip the waiting and activate the new SW
    this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
  }
}
