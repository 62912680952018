import { axiosInstance } from "@/plugins/axios";
import SubscriptionModule from "@/store/modules/Subscription";
import axios from "axios";
import { subscription, updateSubscriptionRequest } from "subscriptionModels";

const Subscriptions = () => {
  const getSubscription = async () => {
    const res = await axiosInstance
      .get("/api/subscriptions/getUserSubscription")
      .catch((err) => {
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status == 404) {
            console.warn("Trust 404");
          }
          return Promise.resolve(null);
        }
        return Promise.reject(err);
      });
    if (res != null && res.status == 200) {
      SubscriptionModule.setSubscription(res.data as subscription);
    } else {
      SubscriptionModule.setSubscription(null);
    }
    console.log("Res for getSub: ", res);
    return Promise.resolve();
  };
  const updateSubscription = async (val: updateSubscriptionRequest) => {
    const res = await axiosInstance.post(
      "/api/subscriptions/updateUserSubscription",
      val
    );

    if (res.status == 200) {
      SubscriptionModule.setSubscription(res.data as subscription);
    } else {
      SubscriptionModule.setSubscription(null);
    }
    console.log("Res for getSub: ", res);
    return Promise.resolve();
  };

  return {
    getSubscription,
    updateSubscription,
  };
};

const temp = Subscriptions();
export default temp;
