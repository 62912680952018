
import { Component, Vue, Watch } from "vue-property-decorator";
import JsonExcel from "@/components/JsonExcel.vue";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { Shifts, CRUDFunctionality, AROs, Downloader } from "@/hooks";
import { AROViewModel, Shift } from "models";
import manageItemModal from "@/views/manage/shifts/ShiftsAddEdit.vue";
import ShiftAttendanceModal from "@/views/manage/shifts/ShiftAttendance.vue";
import dayjs from "dayjs";

@Component({
  components: {
    JsonExcel,
    manageItemModal,
    ShiftAttendanceModal,
  },
  filters: {
    convertDate: function (value: string) {
      return dayjs(value).format("YYYY-MM-DD");
    },
  },
})
export default class ManageShifts extends Vue {
  fromDate = dayjs().subtract(2, "day").format("YYYY-MM-DD");
  fromMenu = false;
  toDate = dayjs().add(1, "day").format("YYYY-MM-DD");
  toMenu = false;
  items: Shift[] = [];
  itemsFiltered: Shift[] = [];
  dev = true;
  loading = false;
  search = "";
  sortDesc = false;
  sortBy = "shiftSite.name";
  expanded = [];
  deleteModal = false;
  valid = false;
  manageItem = false;
  manageItemName = "";
  manageItemId = 0;
  refresh = false;
  attendanceModal = false;
  modalData = {
    type: " ",
    field: {},
  };
  attendanceData: Shift | null = null;
  rules = {
    required: (value: string): boolean | string =>
      !!value || "This field is Required.",
  };
  headers = [
    { text: "", value: "actions", sortable: false },
    { text: "Site/Area Name", value: "shiftSite.name" },
    { text: "Start Date", value: "startDate" },
    { text: "End Date", value: "endDate" },
    { text: "Start Time", value: "startTime" },
    { text: "End Time", value: "endTime" },
    { text: "", value: "data-table-expand" },
  ];
  expReports: {
    "Site/Area Name": string;
    "Start Date": string;
    "End Date": string;
    "Start Time": string;
    "End Time": string;
    "Team Name": string;
    "Team Type": string;
    "Team Members": string;
    "StandBy Shift Members": string;
  }[] = [];
  expFields = {
    "Site/Area Name": "Site/Area Name",
    "Start Date": "Start Date",
    "End Date": "End Date",
    "Start Time": "Start Time",
    "End Time": "End Time",
    "Team Name": "Team Name",
    "Team Type": "Team Type",
    "Team Members": "Team Members",
    "StandBy Shift Members": "StandBy Shift Members",
  };

  shouldDisplayForRole(name: string | undefined): boolean {
    const has = CRUDFunctionality.hasAccess(name);
    //console.log("Name: ", name, has);
    return has;
  }
  /**
   * ARO's
   */
  aros: AROViewModel[] = [];
  arosSelected: AROViewModel[] = [];
  async loadAros(): Promise<void> {
    this.aros = (await AROs.getAros()).arOs;
  }

  @Watch("refresh", { immediate: true })
  modalDataChanged(): void {
    if (this.refresh == true) {
      this.loadItems();
    }
  }

  openAttendanceModal(i: Shift): void {
    this.attendanceData = i;
    this.attendanceModal = true;
  }

  openEditItem(i: Shift): void {
    this.modalData.type = "edit";
    var items = new Object();
    items = {
      id: i.id,
      shiftSiteID: String(i.shiftSiteID),
      teamID: String(i.teamID),
      team: i.team,
      startDate:
        i.startDate != null ? dayjs(i.startDate).format("YYYY-MM-DD") : "",
      endDate: i.endDate != null ? dayjs(i.endDate).format("YYYY-MM-DD") : "",
      startTime: i.startTime,
      endTime: i.endTime,
      createdBy: i.createdBy,
      createdDate: i.createdDate,
      updatedBy: i.updatedBy,
      updatedDate: i.updatedDate,
      shiftSite: i.shiftSite,
      securityFirmID: i.securityFirmID,
      securityFirm: i.securityFirm,
      shiftArmResponseOfficers: i.shiftArmResponseOfficers,
      currentlyOnDuty: i.currentlyOnDuty,
    };

    /* if (elem == "teamID") {
        i.teamID = elem.toString();
      } else {
        return elem;
      } */
    console.log(items);

    this.modalData.field = items;
    this.refresh = false;
    this.manageItem = true;
  }

  openAddItem(): void {
    this.modalData.type = "add";
    this.modalData.field = {};
    this.refresh = false;
    this.manageItem = true;
  }

  openDeleteItem(i: Shift): void {
    this.manageItemName =
      (i.shiftSite != null ? i.shiftSite.name : "") +
      " (" +
      (i.startDate != null ? dayjs(i.startDate).format("YYYY-MM-DD") : "") +
      " " +
      i.startTime +
      " - " +
      (i.endDate != null ? dayjs(i.endDate).format("YYYY-MM-DD") : "") +
      " " +
      i.endTime +
      ")";
    this.manageItemId = i.id;
    this.deleteModal = true;
  }

  async deleteItem(): Promise<void> {
    try {
      const res = await Shifts.deleteShift(this.manageItemId);
      this.$notifCreator.createSuccessNotification(
        "Shift Deleted Succesfully."
      );
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.deleteModal = false;
      this.loadItems();
    }
  }
  updateReportsFiltered(event: any) {
    this.itemsFiltered = event ? (event as any[]) : this.items;
  }
  get getReportsFiltered() {
    return this.populateExportData(this.itemsFiltered);
  }
  async generatePDF(): Promise<void> {
    try {
      const columns = [
        { header: "Site/Area Name", dataKey: "Site/Area Name" },
        { header: "Start Date", dataKey: "Start Date" },
        { header: "End Date", dataKey: "End Date" },
        { header: "Start Time", dataKey: "Start Time" },
        { header: "End Time", dataKey: "End Time" },
        { header: "Team Name", dataKey: "Team Name" },
        { header: "Team Type", dataKey: "Team Type" },
        { header: "Team Members", dataKey: "Team Members" },
        { header: "StandBy Shift Members", dataKey: "StandBy Shift Members" },
      ];

      await Downloader.generatePdfAndSave({
        fileName: `Shift (${this.fromDate}-${this.toDate})`,
        columns: columns,
        body: this.expReports,
        horizontalPageBreakRepeat: "Team Name",
      });
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async loadItems(): Promise<void> {
    try {
      this.loading = true;
      await this.loadAros();
      const res = await Shifts.getShifts(
        this.fromDate,
        this.toDate,
        this.arosSelected.map((e) => e.id!.toString())
      );
      this.items = res;
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
    this.expReports = this.populateExportData(this.items);
  }

  async mounted(): Promise<void> {
    await this.loadItems();
  }

  populateExportData(items: Shift[]) {
    return items.map((elem: Shift) => {
      return {
        "Site/Area Name": elem.shiftSite != null ? elem.shiftSite.name : "N/A",
        "Start Date":
          elem.startDate != null
            ? dayjs(elem.startDate).format("YYYY-MM-DD")
            : "N/A",
        "End Date":
          elem.startDate != null
            ? dayjs(elem.endDate).format("YYYY-MM-DD")
            : "N/A",
        "Start Time": elem.startTime != null ? elem.startTime : "N/A",
        "End Time": elem.startTime != null ? elem.startTime : "N/A",
        "Team Name": elem.team != null ? elem.team.name : "N/A",
        "Team Type":
          elem.team != null
            ? elem.team.isSecurityGuardTeam == true
              ? "SO Team"
              : "ARO Team"
            : "N/A",
        "Team Members":
          elem.shiftArmResponseOfficers != null
            ? String(
                elem.shiftArmResponseOfficers
                  .filter((i) => i.isTeamMember == true)
                  .map((i) =>
                    i.armResponseOfficer != null
                      ? i.armResponseOfficer.name
                      : "N/A"
                  )
              )
            : "N/A",
        "StandBy Shift Members":
          elem.shiftArmResponseOfficers != null
            ? String(
                elem.shiftArmResponseOfficers
                  .filter((i) => i.isTeamMember == false)
                  .map((i) =>
                    i.armResponseOfficer != null
                      ? i.armResponseOfficer.name
                      : "N/A"
                  )
              )
            : "N/A",
      };
    });
  }
}
