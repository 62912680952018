import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Login from "@/views/Login.vue";
import ResetPassword from "@/views/ForgotPassword.vue";
import Dashboard from "@/views/Dashboard.vue";
import MyPermits from "@/components/MyPermits.vue";
import AROPocketbook from "@/views/pocketbook/aro.vue";
import SupervisorPocketbook from "@/views/pocketbook/supervisor.vue";
// Management
import ManagePermits from "@/views/manage/Permit.vue";
import ManageAros from "@/views/manage/ARO.vue";
import ManageUsers from "@/views/manage/Users.vue";
import ManageTeams from "@/views/manage/Teams.vue";
import ManageIncidents from "@/views/manage/Incidents.vue";
import ManageShifts from "@/views/manage/Shifts.vue";
import ManageDocuments from "@/views/manage/Documents.vue";
// Reports
import AmmunitionReport from "@/views/report/Ammunition.vue";
import ARO_SOReport from "@/views/report/ARO_SO.vue";
import ComplianceReport from "@/views/report/Compliance.vue";
import FirearmReport from "@/views/report/Firearm.vue";
import IncidentReport from "@/views/report/Incident.vue";
import ExceptionsReport from "@/views/report/Exceptions.vue";
import ShiftReport from "@/views/report/Shift.vue";
import PermitReport from "@/views/report/Permit.vue";

// Registers
import DailyCombined from "@/views/registers/DailyCombined.vue";
import ManageFirearms from "@/views/registers/Firearms.vue";
import ManageAmmunition from "@/views/registers/Ammunition.vue";
import FirearmPermit from "@/views/permits/Firearm.vue";

import QuickLinks from "@/components/QuickLinks.vue";
import Overview from "@/views/dashboard/Overview.vue";
import OfflinePermits from "@/components/OfflinePermits.vue";
import Archived from "@/components/Archived/Archived.vue";
import ActivityLog from "@/components/ActivityLog.vue";
import Profile from "@/views/Profile.vue";
//import TestSignature from "@/components/TestSignature.vue";
import Report from "@/views/Report.vue";
import ParentRoute from "@/views/ParentRoute.vue";
import AppBar from "../components/Appbar.vue";
import NavDrawer from "../components/NavDrawer.vue";

import AuthModule from "@/store/modules/Auth";
import {
  Authenticate,
  Navigation,
  Notifications,
  User,
  Storage,
  Subscription,
} from "@/hooks";
import SubscriptionModule from "@/store/modules/Subscription";
import Auth from "@/store/modules/Auth";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: {
      name: "Login",
    },
  },
  /* {
    path: "/test",
    name: "test",
    components: {
      main: TestSignature,
    },
  }, */
  {
    path: "/login",
    name: "Login",
    components: {
      main: Login,
    },
  },
  {
    path: "/reset-password*",
    name: "ResetPassword",
    components: {
      main: ResetPassword, // TODO@CVD : Check this endpoint out.
    },
  },
  {
    // path: "/permits/firearm/assignment/",
    path: "/permits/firearm/assignment/:id", // http://localhost:8080/#/permits/firearm/assignment?id=89d93800-2296-45da-83f7-2982ef0e83ae OR http://localhost:8080/#/permits/firearm/assignment/89d93800-2296-45da-83f7-2982ef0e83ae/
    name: "FirearmAssignmentPermit",
    components: {
      main: FirearmPermit,
    },
  },
  {
    path: "/dashboard",
    components: {
      main: Dashboard,
      nav: NavDrawer,
      appBar: AppBar,
    },
    children: [
      {
        path: "home",
        name: "dash.quickLinks",
        component: QuickLinks,
      },
      {
        path: "overview",
        name: "dash.overview",
        component: Overview,
      },
      {
        path: "pocketbook/aro",
        name: "dash.pocketbook.aro",
        component: AROPocketbook,
      },
      {
        path: "pocketbook/supervisor",
        name: "dash.pocketbook.supervisor",
        component: SupervisorPocketbook,
      },
      {
        path: "my-permits",
        name: "dash.myPermits",
        component: MyPermits,
      },
      {
        path: "offline-permits",
        name: "dash.offlinePermits",
        component: OfflinePermits,
      },
      {
        path: "archived",
        name: "dash.archived",
        component: Archived,
      },
      {
        path: "activity-log",
        name: "dash.activityLog",
        component: ActivityLog,
      },
      {
        path: "profile",
        name: "dash.profile",
        component: Profile,
      },
      {
        path: "registers",
        component: ParentRoute,
        children: [
          {
            path: "daily-combined",
            name: "dash.registers.dailyCombined",
            component: DailyCombined,
          },
          {
            path: "ammo",
            name: "dash.registers.ammo",
            component: ManageAmmunition,
          },
          {
            path: "firearms",
            name: "dash.registers.firearms",
            component: ManageFirearms,
          },
        ],
      },
      {
        path: "manage",
        component: ParentRoute,
        children: [
          {
            path: "documents",
            name: "dash.manage.documents",
            component: ManageDocuments,
          },
          {
            path: "permits",
            name: "dash.manage.permits",
            component: ManagePermits,
          },
          // {
          //   path: "ammo",
          //   name: "dash.manage.ammo",
          //   component: ManageAmmunition,
          // },
          // {
          //   path: "firearms",
          //   name: "dash.manage.firearms",
          //   component: ManageFirearms,
          // },
          {
            path: "armed-response-officer",
            name: "dash.manage.aro",
            component: ManageAros,
          },
          {
            path: "users",
            name: "dash.manage.users",
            component: ManageUsers,
          },
          {
            path: "teams",
            name: "dash.manage.teams",
            component: ManageTeams,
          },
          {
            path: "incidents",
            name: "dash.manage.incidents",
            component: ManageIncidents,
          },
          {
            path: "shifts",
            name: "dash.manage.shifts",
            component: ManageShifts,
          },
        ],
      },
      {
        path: "report",
        component: Report,
        children: [
          {
            path: "aro_so",
            name: "dash.report.aro_so",
            component: ARO_SOReport,
          },
          {
            path: "ammo",
            name: "dash.report.ammo",
            component: AmmunitionReport,
          },
          {
            path: "compliance",
            name: "dash.report.compliance",
            component: ComplianceReport,
          },
          {
            path: "exceptions",
            name: "dash.report.exceptions",
            component: ExceptionsReport,
          },
          {
            path: "firearms",
            name: "dash.report.firearms",
            component: FirearmReport,
          },
          {
            path: "incident",
            name: "dash.report.incident",
            component: IncidentReport,
          },
          {
            path: "shift",
            name: "dash.report.shift",
            component: ShiftReport,
          },
          {
            path: "permit",
            name: "dash.report.permit",
            component: PermitReport,
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

router.beforeEach(async (to, from, next) => {
  // const key = localStorage.getItem("zero-eight/login");
  // if (key != null) {
  //   const parsed = JSON.parse(key);
  //   if (parsed.expiration) {
  //     if (new Date(parsed.expiration) > new Date()) {
  //       // console.log("Before each: ", parsed);
  //       AuthModule.setLoggedIn({
  //         userDetailsResponse: {
  //           accessToken: parsed.accessToken,
  //           expiration: parsed.expiration,
  //           username: parsed.username,
  //           issuePermit: parsed.issuePermit,
  //           incidents: parsed.incidents,
  //           reports: parsed.reports,
  //         },
  //         roles: parsed.roles,
  //       });
  //     } else {
  //       AuthModule.removeLoggedIn();
  //     }
  //   } else {
  //     AuthModule.removeLoggedIn();
  //   }
  // } else {
  //   AuthModule.removeLoggedIn();
  // }
  const res = await Storage.getLogin();
  console.log("Login ", res);
  if (res) {
    const valid = await User.processNewJWT(res);
    console.log("JWT ", valid);
    if (valid) {
      console.log("JWT calid", valid);
      // call user roles and subscription
      await Authenticate.getUser();
      await Authenticate.getRoles();
    } else {
      console.log("JWT not valid", valid);
      Notifications.createErrorNotification("Logged out: expired");
      await Storage.removeLogin();
      AuthModule.removeLoggedIn();
    }
  }
  console.log("To ", to);
  console.log("From ", from);
  // Public Views
  if (to.name == "ResetPassword") {
    next();
    return;
  }
  if (to.name == "FirearmAssignmentPermit") {
    // TODO@CVD: Check this does not allow malicious bypasses.
    next();
    return;
  }

  if (to.name !== "Login" && AuthModule.isLoggedIn == false) {
    next({
      name: "Login",
      params: {
        from: from == undefined ? "" : from.path,
      },
    });
  } else if (to.name == "Login" && AuthModule.isLoggedIn == true) {
    if (Navigation.hasAccess("dash.myPermits")) {
      next({
        name: "dash.myPermits",
      });
    } else {
      next({
        name: "dash.overview",
      });
    }
  } else {
    if (Navigation.hasAccess(to.name) == true || to.name == "Login") {
      next();
    } else {
      // next();
      if (AuthModule.isLoggedIn == true && to.name != "dash.quickLinks") {
        next({
          name: "dash.overview",
        });
        return;
      }
      console.log(
        "Should stay on same page. Not allowed to.name: ",
        to.name,
        " from: ",
        from.name
      );
    }
  }
});
export default router;
