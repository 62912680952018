import { axiosInstance } from "@/plugins/axios";
import { ArmResponseOfficer, AroViewModelWrapper, ManageUser } from "models";

const AROs = () => {
  const baseUrl = "/api/aROsAPI";

  const getAros = async (): Promise<AroViewModelWrapper> => {
    const res = await axiosInstance.get(baseUrl + "/getARO");
    return Promise.resolve(res.data);
  };

  const getSupervisor = async (): Promise<ManageUser[]> => {
    const res = await axiosInstance.get(baseUrl + "/getSupervisor");
    return Promise.resolve(res.data);
  };

  const getAROOrSGList = async (
    isSG: boolean
  ): Promise<ArmResponseOfficer[]> => {
    const res = await axiosInstance.get(
      baseUrl + `/getAROOrSGList?IsSG=${isSG}`
    );
    return Promise.resolve(res.data);
  };

  const getAROOrSGListTeamID = async (
    isSG: boolean,
    id: number
  ): Promise<ArmResponseOfficer[]> => {
    const res = await axiosInstance.get(
      baseUrl + `/getAROOrSGList?IsSG=${isSG}&teamId=${id}`
    );
    return Promise.resolve(res.data);
  };

  const addARO = async (data: FormData): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/addARO", data);
    return Promise.resolve(res.data);
  };

  const editARO = async (data: FormData): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/editARO", data);
    return Promise.resolve(res.data);
  };

  const deleteARO = async (id: number): Promise<void> => {
    const res = await axiosInstance.put(
      baseUrl + `/deleteARO?armResponseOfficerID=${id}`
    );
    return Promise.resolve(res.data);
  };

  const archiveARO = async (id: number, reason: string): Promise<void> => {
    const res = await axiosInstance.put(
      baseUrl + `/archiveARO?armResponseOfficerID=${id}&reason=${reason}`
    );
    return Promise.resolve(res.data);
  };

  const restoreARO = async (id: number): Promise<void> => {
    const res = await axiosInstance.put(baseUrl + `/restoreARO?aroID=${id}`);
    return Promise.resolve(res.data);
  };

  const importARO = async (file: FormData): Promise<void> => {
    const res = await axiosInstance.put(baseUrl + `/importARO`, file);
    return Promise.resolve(res.data);
  };

  return {
    getAros,
    getSupervisor,
    getAROOrSGList,
    getAROOrSGListTeamID,
    addARO,
    editARO,
    deleteARO,
    archiveARO,
    restoreARO,
    importARO,
  };
};
const temp = AROs();
export default temp;
