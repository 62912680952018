
import { Component, PropSync, Vue, Mixins } from "vue-property-decorator";
import JsonExcel from "@/components/JsonExcel.vue";
import PDF from "@/mixins/PDF.vue";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { Archive, AROs, Documents, Downloader } from "@/hooks";
import { ArchivedARO } from "models";
import dayjs from "dayjs";

@Component({
  components: {
    JsonExcel,
  },
  filters: {
    fileName: function (value: string) {
      if (value.length > 30) {
        const front = value.substring(0, 20);
        const end = value.substring(value.length - 10);
        return front + "..." + end;
      } else {
        return value;
      }
    },
  },
})
export default class Archived_AROs extends Mixins(PDF) {
  @PropSync("loading") loading!: boolean;
  items: ArchivedARO[] = [];
  itemsFiltered: ArchivedARO[] = [];
  search = "";
  sortDesc = false;
  sortBy = "name";
  restoreModal = false;
  archivedItemInfo = "";
  archivedItemId = 0;
  headers = [
    { text: "", value: "actions", sortable: false },
    { text: "First Name", value: "name" },
    { text: "Surname", value: "surname" },
    { text: "ID No", value: "idNumber" },
    { text: "ARO/SO", value: "isSecurityGuard" },
    { text: "Archive Reason", value: "archiveReason" },
    { text: "", value: "data-table-expand" },
  ];
  expReports: {
    "First Name": string;
    Surname: string;
    "ID No": string;
    "ARO/SO": string;
    "Archive Reason": string;
    "PSIRA Reg No": string;
    "Contact No": string;
    "Physical Address": string;
    Team: any;
    "Reports To": string;
    Picture: string;
  }[] = [];
  expFields = {
    "First Name": "name",
    Surname: "surname",
    "ID No": "idNumber",
    "ARO/SO": "isSecurityGuard",
    "Archive Reason": "archiveReason",
    "PSIRA Reg No": "psiraRegistrationNumber",
    "Contact No": "contactNumber",
    "Physical Address": "address",
    Team: "teamName",
    "Reports To": " ",
    Picture: " ",
  };

  openArchiveItem(i: ArchivedARO): void {
    this.archivedItemInfo = i.name + " " + i.surname;
    this.archivedItemId = i.id;
    this.restoreModal = true;
  }

  async restoreItem(): Promise<void> {
    try {
      const res = await AROs.restoreARO(this.archivedItemId);
      this.$notifCreator.createSuccessNotification("ARO Restored Succesfully.");
      this.loadItems();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.restoreModal = false;
    }
  }

  async mounted(): Promise<void> {
    await this.loadItems();
  }

  async loadItems(): Promise<void> {
    try {
      this.loading = true;
      const res = await Archive.getArchivedARO();
      this.items = res;
      this.expReports = this.populateExportData(this.items);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  updateReportsFiltered(event: any) {
    this.itemsFiltered = event ? (event as any[]) : this.items;
  }
  get getReportsFiltered() {
    return this.populateExportData(this.itemsFiltered);
  }
  async generatePDF(): Promise<void> {
    try {
      // this.loading.pdf = true;
      const columns = [
        { header: "First Name", dataKey: "First Name" },
        { header: "Surname", dataKey: "Surname" },
        { header: "ID No", dataKey: "ID No" },
        { header: "ARO/SO", dataKey: "ARO/SO" },
        { header: "Archive Reason", dataKey: "Archive Reason" },
        { header: "PSIRA Reg No", dataKey: "PSIRA Reg No" },
        { header: "Contact No", dataKey: "Contact No" },
        { header: "Physical Address", dataKey: "Physical Address" },
        { header: "Team", dataKey: "Team" },
        { header: "Reports To", dataKey: "Reports To" },
        { header: "Picture", dataKey: "Picture" },
      ];
      await Downloader.generatePdfAndSave({
        fileName: `Archived AROs (${dayjs().format("YYYY-MM-DD")})`,
        columns: columns,
        body: this.expReports,
        horizontalPageBreakRepeat: "First Name",
      });
    } catch (err) {
      return Promise.reject(err);
    }
  }
  populateExportData(items: ArchivedARO[]) {
    return items.map((elem: ArchivedARO) => {
      return {
        "First Name": elem.name != null ? elem.name : " ",
        Surname: elem.surname != null ? elem.surname : " ",
        "ID No": elem.idNumber != null ? elem.idNumber : " ",
        "ARO/SO":
          elem.isSecurityGuard != null
            ? elem.isSecurityGuard == true
              ? "SO"
              : "ARO"
            : " ",
        "Archive Reason":
          elem.archiveReason != null
            ? elem.archiveReason == 1
              ? "Resigned"
              : elem.archiveReason == 2
              ? "Dismissed"
              : elem.archiveReason == 3
              ? "Absconded"
              : elem.archiveReason == 4
              ? "Retrenched"
              : elem.archiveReason == 5
              ? "Medically Boarded"
              : elem.archiveReason == 6
              ? "Deceased"
              : elem.archiveReason == 7
              ? "Suspended"
              : String(elem.archiveReason)
            : " ",
        "PSIRA Reg No":
          elem.psiraRegistrationNumber != null
            ? elem.psiraRegistrationNumber
            : " ",
        "Contact No": elem.contactNumber != null ? elem.contactNumber : " ",
        "Physical Address": elem.address != null ? elem.address : " ",
        Team: elem.teamName != null ? elem.teamName : " ",
        "Reports To": " ",
        Picture: " ",
      };
    });
  }

  getColor(i: any): string {
    if (i >= 45) return "green";
    else if (i >= 30 && i <= 44) return "yellow";
    else if (i >= 16 && i <= 29) return "orange";
    else if (i >= 0 && i <= 15) return "red";
    else return "red";
  }

  async downloadwPath(i: any): Promise<void> {
    /* window.open(process.env.VUE_APP_API_BASE + "/" + i); */
    var ext = i.substr(i.lastIndexOf(".") + 1);
    var type = "image/jpeg";
    if (ext == "jpeg" || ext == "jpg") {
      type = "image/jpeg";
    } else if (ext == "pdf") {
      type = "application/pdf";
    } else if (ext == "png") {
      type = "image/png";
    }
    try {
      await Documents.getFile(i).then((response: any) => {
        const url = window.URL.createObjectURL(
          new Blob([response], {
            type: type,
          })
        );
        window.open(url);
        console.log(response);
      });
    } catch (err) {
      const req = await require("@/assets/FileNotFound.png");
      const resO = await fetch(req);
      const img = await resO.blob();
      const url = URL.createObjectURL(img);
      window.open(url);
      this.$notifCreator.createErrorNotification("Can't find Image.");
      return Promise.reject(err);
    }
  }
}
