
import { Component, Vue, Prop, PropSync, Watch } from "vue-property-decorator";
import { AROs } from "@/hooks";
import { AroResponseCounts, AROViewModel, ManageUser } from "models";
import { UPSERT } from "@/enums/crud";
import AuthModule from "@/store/modules/Auth";

@Component({})
export default class manageAROModal extends Vue {
  @Prop() counts!: AroResponseCounts;
  @Prop({ default: false }) readonly upsert!: UPSERT; // TODO@CVD: = "" ?
  // @Prop() readonly update!: boolean = false;
  // @PropSync("refresh") refreshData!: boolean;
  @PropSync("datum") data!: AROViewModel | null; // Passed by reference ? // reportsToUser: [{ name: string; value: string }];
  form: AROViewModel | null = null;
  get display(): boolean {
    return this.data !== null;
  }
  get create(): boolean {
    return !this.update;
  }
  // isArmedResponseOfficerTest = true;
  get update(): boolean {
    if (typeof this.upsert === "boolean") {
      return this.upsert;
    } else {
      if (["", "true"].includes(this.upsert)) {
        return true;
      } else {
        return false;
      }
    }
  }
  get aroRules() {
    const rules = {
      limit: (v: boolean) => {
        if (v == true) {
          if (
            this.getLimits &&
            this.counts.aroCount -
              (this.data && this.data.isSecurityGuard == false ? 1 : 0) <
              this.getLimits.aroLimit
          ) {
            return true;
          }
        } else {
          return true;
        }
        return false;
      },
    };
    return [rules.limit];
  }
  get soRules() {
    const rules = {
      limit: (v: boolean) => {
        if (v == true) {
          if (
            this.getLimits &&
            this.counts.soCount -
              -(this.data && this.data.isSecurityGuard == true ? 1 : 0) <
              this.getLimits.soLimit
          ) {
            return true;
          }
        } else {
          return true;
        }
        return false;
      },
    };
    return [rules.limit];
  }
  get getLimits() {
    return AuthModule.getUserLimits;
  }
  valid = false;
  redacted = false;
  picture: File | Blob | null = null;
  signature: File | Blob | null = null;
  password: string | null = "";
  confirmPassword: string | null = "";
  rules = {
    required: (value: string): boolean | string =>
      !!value || "This field is Required.",
    uppercase: (value: string): boolean | string => {
      const pattern = /^(?=.*[A-Z])/;
      return (
        (value ? pattern.test(value) : true) ||
        "Requires atleast 1 uppercase letter"
      );
    },
    lowercase: (value: string): boolean | string => {
      const pattern = /^(?=.*[a-z])/;
      return (
        (value ? pattern.test(value) : true) ||
        "Requires atleast 1 lowercase letter"
      );
    },
    digit: (value: string): boolean | string => {
      const pattern = /^(?=.*\d)/;
      return (value ? pattern.test(value) : true) || "Requires atleast 1 digit";
    },
    special: (value: string): boolean | string => {
      const pattern = /^(?=.*\W)/;
      return (
        (value ? pattern.test(value) : true) ||
        "Requires atleast 1 special character"
      );
    },
    id: (value: string): boolean | string =>
      (value ? value.length == 13 && /^(?!^0*$)/.test(value) : true) ||
      "Requires a valid 13 digit ID number",
    cell: (value: string): boolean | string =>
      (value ? value.length == 10 : true) || "Requires 10 digits",
    email: (value: string): boolean | string => {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Invalid e-mail.";
    },
    confirmation: (value: string): boolean | string =>
      this.passwordConfirmation(value) ||
      `Passwords do not match ${this.password}`,
    fileSize: (value: { size: number }): boolean | string =>
      !value || value.size < 50000000 || "Max File Size is 50MB",
  };

  // @Watch("$refs")
  @Watch("data")
  init(): void {
    console.log("$refs 1", this.$refs);
    const form = this.$refs.form as HTMLFormElement;
    this.$nextTick(() => {
      console.log("$refs 2", this.$refs, this.data?.isSecurityGuard);
      // if (form) {
      //   form.reset();
      // }
      if (this.data) {
        this.form = { ...this.data } as AROViewModel;
      } // Check for null|use default}
      // if (form) {
      //   if (this.create) {
      //     form.resetValidation();
      //   } else {
      //     form.validate();
      //   }
      // }
    });
  }

  // updated(): void {
  //   console.log("Updated", this.$refs);
  //   // form.reset();
  // }
  /**
   * https://stackoverflow.com/a/57056501
   */
  @Watch("password")
  @Watch("confirmPassword")
  passwordConfirmation(pass: string): boolean {
    if (this.password) {
      if (pass == this.password) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  // isArmResponseOfficer = true;
  get isArmedResponseOfficer(): boolean | null {
    if (this.form !== null) {
      // if ()
      return !this.form.isSecurityGuard;
    } else {
      return null;
    }
  }
  set isArmedResponseOfficer(value: boolean | null) {
    if (this.form !== null && value !== null) {
      this.form.isSecurityGuard = !value;
    }
  }

  // @Watch("form.isSecurityGuard", { immediate: true })
  // onisSecurityGaurdChange(): void {
  //   if (this.form) {
  //     if (this.form.isSecurityGuard == true) {
  //       this.isArmedResponseOfficer = false;
  //       this.form.isSecurityGuard = true;
  //     } else {
  //       this.isArmedResponseOfficer = true;
  //       this.form.isSecurityGuard = true;
  //     }
  //   }
  // }

  supervisors: any[] = [];
  @Watch("data")
  async loadSupervisors(): Promise<void> {
    try {
      const res = await AROs.getSupervisor();
      this.supervisors = res.map((elem: any) => {
        return {
          name: elem.name + " " + elem.surname,
          value: elem.id,
        };
      });
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }

  closeModal(): void {
    this.password = "";
    this.confirmPassword = "";
    this.picture = null;
    this.signature = null;
    // this.manageItem = false;
    this.data = null; // Make this.form =null
    // (this.$refs.form as HTMLFormElement).reset();
  }

  async submit(): Promise<void> {
    console.log("Submit :", this.form);
    var formdata = new FormData();
    const form = this.form!;
    Object.keys(form).forEach((elem) => {
      if (form[elem as keyof AROViewModel] != null) {
        // https://stackoverflow.com/a/64217699
        if (form["archiveReason"] == 0) {
          form["archiveReason"] = null;
        }
        formdata.append(elem, form[elem as keyof AROViewModel] as any); // TODO@CVD should really cast to FormDataEntryValue, but can't seem to import the type.
      }
    });
    if (this.password && this.password === this.confirmPassword) {
      formdata.append("password", this.password);
      formdata.append("confirmPassword", this.confirmPassword);
    }
    formdata.append("emailAddress", form.email.trim());
    formdata.append("picture", this.picture ? this.picture : "");
    formdata.append("signature", this.signature ? this.signature : "");
    formdata.append(
      "isSG",
      String(form.isSecurityGuard == true ? true : false)
    );
    try {
      console.log("commit");
      const res = await AROs.editARO(formdata);
      this.$notifCreator.createSuccessNotification("ARO Edited Succesfully!");
      this.$emit("submit");
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.closeModal();
      // this.refreshData = true;
    }
  }
}
