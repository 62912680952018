
import { Component, Vue, Watch } from "vue-property-decorator";
import PermitExpansionPanel from "@/partials/PermitExpansionPanel.vue";
import ReturnPermit from "@/components/ReturnPermit.vue";
import IssuePermit from "@/views/manage/permits/IssuePermit.vue";
import AuthModule from "@/store/modules/Auth";
import JsonExcel from "@/components/JsonExcel.vue";
import {
  Permits,
  IndexedDB,
  CRUDFunctionality,
  AROs,
  Downloader,
  ShiftSite,
} from "@/hooks";
import Signature from "@/components/Signature.vue";
import {
  AROViewModel,
  ConfirmPermitViewModel,
  OfflinePermitType,
  OldPermit,
  ShiftSite as ShiftSiteModel,
} from "models";
import dayjs from "dayjs";
import Auth from "@/store/modules/Auth";
import { filter } from "vue/types/umd";

@Component({
  components: {
    PermitExpansionPanel,
    ReturnPermit,
    IssuePermit,
    JsonExcel,
    Signature,
  },
})
export default class ManagePermits extends Vue {
  permitFilter: string | null = null;
  sortDesc = true;
  loading = false;
  newPermitModal = false;
  selectedItem = 0;
  reports: OldPermit[] = [];
  permitsFiltered: OldPermit[] = [];
  status = [
    { description: "Issued/ Pending Action" },
    { description: "Returned", color: "blue" },
    { description: "Approved", color: "success" },
    { description: "Declined", color: "error" },
  ];
  filterOnStatus: string[] | null = null;

  /**
   * ARO's
   */

  aros: AROViewModel[] = [];
  arosSelected: AROViewModel[] = [];
  async loadAros(): Promise<void> {
    const res = await AROs.getAros();
    this.aros = res.arOs;
  }
  /**
   * Shift Sites
   */

  shiftSites: ShiftSiteModel[] = [];
  shiftSitesSelected: ShiftSiteModel[] = [];
  async loadShiftSites(): Promise<void> {
    this.shiftSites = await ShiftSite.getShiftSites();
  }
  outstandingPermits = false;
  permits: OldPermit[] = [];
  permitDatePicker = false;
  dateFilters = {
    to: {
      active: false,
      date: dayjs().format("YYYY-MM-DD"),
    },
    from: {
      active: false,
      date: dayjs().subtract(4, "days").format("YYYY-MM-DD"),
    },
  };
  signatureOpen = false;
  permitToBeHandled: null | OldPermit = null;
  signature: string | null = null;
  returnPermit: {
    open: boolean;
    permit: null | OldPermit;
  } = {
    open: false,
    permit: null,
  };
  currentPdfBuffer: ArrayBuffer | null = null;
  currentPdf: Blob | null = null;
  sortByDate(a: OldPermit, b: OldPermit): number {
    const aDate = new Date(a.toDate).getTime();
    const bDate = new Date(b.toDate).getTime();
    if (aDate - bDate > 0) {
      if (this.sortDesc) return -1;
      else return 1;
    } else if (aDate - bDate < 0) {
      if (this.sortDesc) return 1;
      else return -1;
    } else {
      if (a.toTime.split(":")[0] > b.toTime.split(":")[0]) {
        if (this.sortDesc) return -1;
        else return 1;
      } else if (a.toTime.split(":")[0] < b.toTime.split(":")[0]) {
        if (this.sortDesc) return -1;
        else return 1;
      } else {
        return 0;
      }
    }
  }

  expReports: any[] = [];

  expFields = {
    "Permit Number": "Permit Number",
    ARO: "ARO",
    Firearm: "Firearm",
    "From Date": "From Date",
    "To Date": "To Date",
    "Firearm S/N": "Firearm S/N",
    "Ammunition Calibre": "Ammunition Calibre",
    "Ammunition Count": "Ammunition Count",
    "From Time": "From Time",
    "To Time": "To Time",
    Area: "Area",
    "Nature Of Duty": "Nature Of Duty",
    "Number Of Magazines": "Number Of Magazines",
    Remarks: "Remarks",
    Returned: "Returned",
    "Returned By": "Returned By",
    "Returned Date": "Returned Date",
    "Ammunition Return": "Ammunition Return",
    "Show ARO Signature": "Show ARO Signature",
    "Show Responsible Person Signature": "Show Responsible Person Signature",
    "Approved By": "Approved By",
    "Approved Date": "Approved Date",
    "Declined By": "Declined By",
    "Declined Date": "Declined Date",
  };

  // elem.firearm.make != null ||
  //           elem.firearm.model != null ||
  //           elem.firearm.type != null ||
  //           elem.firearm.expiryDateStr != null
  //             ? elem.make +
  //               ", " +
  //               elem.model +
  //               ", " +
  //               elem.type +
  //               ", " +
  //               elem.expiryDateStr
  //             : " ",

  // elem.armResponseOfficer.name != null || elem.armResponseOfficer.surname != null
  //           ?

  populateExportData(items: any[]) {
    return items.map((elem) => {
      return {
        "Permit Number":
          elem.savedPermitNumber != null ? elem.savedPermitNumber : "",
        ARO:
          (elem.armResponseOfficer.name != null
            ? elem.armResponseOfficer.name
            : "") +
          ", " +
          (elem.armResponseOfficer.surname != null
            ? elem.armResponseOfficer.surname
            : ""),

        Firearm:
          (elem.firearm.make != null ? elem.firearm.make : "") +
          ", " +
          (elem.firearm.model != null ? elem.firearm.model : "") +
          ", " +
          (elem.firearm.type != null ? elem.firearm.type : "") +
          ", " +
          (elem.firearm.expiryDateStr != null
            ? elem.firearm.expiryDateStr
            : ""),
        "From Date": elem.fromDateStr != null ? elem.fromDateStr : "",
        "To Date": elem.toDateStr != null ? elem.toDateStr : "",
        "Firearm S/N": elem.firearmID != null ? elem.firearmID : "",
        "Ammunition Calibre":
          elem.ammunition.calibre != null ? elem.ammunition.calibre : "",
        "Ammunition Count":
          elem.ammunitionCount != null ? elem.ammunitionCount : "",
        "From Time": elem.fromTime != null ? elem.fromTime : "",
        "To Time": elem.toTime != null ? elem.toTime : "",
        Area: elem.shiftSite.name != null ? elem.shiftSite.name : "",
        "Nature Of Duty": elem.natureOfDuty != null ? elem.natureOfDuty : "",
        "Number Of Magazines":
          elem.numberOfMagazines != null ? elem.numberOfMagazines : "",
        Remarks:
          elem.ammunitionReturn != null
            ? elem.ammunitionReturn.remarks != null
              ? elem.ammunitionReturn.remarks
              : ""
            : "N/A",
        Returned:
          elem.isReturned != null
            ? elem.isReturned == true
              ? "Yes"
              : "No"
            : "",
        "Returned By":
          elem.ammunitionReturn != null
            ? elem.ammunitionReturn.createdBy != null
              ? elem.ammunitionReturn.createdBy
              : ""
            : "",

        "Returned Date":
          elem.ammunitionReturn != null
            ? dayjs(elem.ammunitionReturn.createdDate).format("YYYY-MM-DD")
            : "",
        "Ammunition Return":
          elem.ammunitionReturn != null
            ? "(total - used = returned) " +
              (elem.ammunitionReturn.totalAllocated != null
                ? elem.ammunitionReturn.totalAllocated
                : "") +
              " - " +
              (elem.ammunitionReturn.usedQuantity != null
                ? elem.ammunitionReturn.usedQuantity
                : "") +
              " = " +
              (elem.ammunitionReturn.returnQuantity != null
                ? elem.ammunitionReturn.returnQuantity
                : "")
            : " REQUIRED",

        "Show ARO Signature":
          elem.showAROSignature != null
            ? elem.showAROSignature == true
              ? "Yes"
              : "No"
            : "",
        "Show Responsible Person Signature":
          elem.showResponsiblePersonSignature != null
            ? elem.showResponsiblePersonSignature == true
              ? "Yes"
              : "No"
            : "",
        "Approved By":
          elem.acceptedBy != null
            ? elem.acceptedBy.userName != null
              ? elem.acceptedBy.userName
              : ""
            : "",
        "Approved Date":
          elem.acceptedDate != null
            ? dayjs(elem.acceptedDate).format("YYYY-MM-DD")
            : "",
        "Declined By": elem.declineBy != null ? elem.declineBy : "",
        "Declined Date":
          elem.declinedDate != null
            ? dayjs(elem.declinedDate).format("YYYY-MM-DD")
            : "",
      };
    });
  }
  async generatePDF(): Promise<void> {
    try {
      // this.loading.pdf = true;
      console.log("PDF Triggered");
      const columns = [
        { header: "Permit #", dataKey: "Permit Number" },
        { header: "ARO", dataKey: "ARO" },
        { header: "Firearm", dataKey: "Firearm" },
        { header: "From Date", dataKey: "From Date" },
        { header: "To Date", dataKey: "To Date" },
        { header: "Firearm S/N", dataKey: "Firearm S/N" },
        { header: "Ammunition Calibre", dataKey: "Ammunition Calibre" },
        { header: "Ammunition Count", dataKey: "Ammunition Count" },
        { header: "From Time", dataKey: "From Time" },
        { header: "To Time", dataKey: "To Time" },
        { header: "Area", dataKey: "Area" },
        { header: "Nature Of Duty", dataKey: "Nature Of Duty" },
        { header: "Number Of Magazines", dataKey: "Number Of Magazines" },
        { header: "Remarks", dataKey: "Remarks" },
        { header: "Returned", dataKey: "Returned" },
        { header: "Returned By", dataKey: "Returned By" },
        { header: "Returned Date", dataKey: "Returned Date" },
        { header: "Ammunition Return", dataKey: "Ammunition Return" },
        { header: "Show ARO Signature", dataKey: "Show ARO Signature" },
        {
          header: "Show Responsible Person Signature",
          dataKey: "Show Responsible Person Signature",
        },
        { header: "Approved By", dataKey: "Approved By" },
        { header: "Approved Date", dataKey: "Approved Date" },
        { header: "Declined By", dataKey: "Declined By" },
        { header: "Declined Date", dataKey: "Declined Date" },
      ];

      console.log("PDF Columns generated");
      await Downloader.generatePdfAndSave({
        fileName: `Permit (${dayjs().format("YYYY-MM-DD")})`,
        columns: columns,
        body: this.getExpData,
        horizontalPageBreakRepeat: "Make",
      });

      console.log("PDF Exported");
    } catch (err) {
      //comment

      console.log("PDF Err: ", err);
    }
  }

  sortByStatus(a: OldPermit, b: OldPermit): number {
    const aRet = a.ammunitionReturn == null && a.declineBy == null;
    const bRet = b.ammunitionReturn == null && b.declineBy == null;

    // item.ammunitionReturn == null &&
    // item.declineBy == null,
    if (aRet < bRet) {
      // if (this.sortDesc) return -1;
      // else return 1;
      return 1;
    } else if (aRet > bRet) {
      // if (this.sortDesc) return 1;
      // else return -1;
      return -1;
    } else {
      return 0;
    }
  }
  // get getFilteredPermits1(): OldPermit[] {
  //   if (this.permitFilter === null) {
  //     return this.permits.sort((a: OldPermit, b: OldPermit) =>
  //       this.sortByDate(b, a)
  //     );
  //   }
  //   const temp = this.permitFilter;
  //   return this.permits.filter(
  //     (e) =>
  //       e.armResponseOfficer.surname
  //         .toLowerCase()
  //         .includes(temp.toLowerCase()) ||
  //       e.armResponseOfficer.name.toLowerCase().includes(temp.toLowerCase()) ||
  //       e.firearm.make.toLowerCase().includes(temp.toLowerCase()) ||
  //       e.firearm.model.toLowerCase().includes(temp.toLowerCase()) ||
  //       e.natureOfDuty.toLowerCase().includes(temp.toLowerCase()) ||
  //       e.firearm.calibre.toLowerCase().includes(temp.toLowerCase())
  //   );
  // }

  get getExpData() {
    return this.populateExportData(this.getFilteredPermits);
  }

  get getFilteredPermits(): OldPermit[] {
    let items = this.permits;

    items = items.sort(this.sortByDate).sort(this.sortByStatus);

    return items;

    // const temp = this.permitFilter;
    // return this.permits.filter(
    //   (e) =>
    //     e.armResponseOfficer.surname
    //       .toLowerCase()
    //       .includes(temp.toLowerCase()) ||
    //     e.armResponseOfficer.name.toLowerCase().includes(temp.toLowerCase()) ||
    //     e.firearm.make.toLowerCase().includes(temp.toLowerCase()) ||
    //     e.firearm.model.toLowerCase().includes(temp.toLowerCase()) ||
    //     e.natureOfDuty.toLowerCase().includes(temp.toLowerCase()) ||
    //     e.firearm.calibre.toLowerCase().includes(temp.toLowerCase())
    // );
  }
  issuePermit(permissions: string[]): boolean {
    console.log(
      "Firearm Permission",
      permissions,
      permissions
        .map((permission) => CRUDFunctionality.hasAccess(permission))
        .every(Boolean)
    );
    return permissions
      .map((permission) => CRUDFunctionality.hasAccess(permission))
      .every(Boolean);
  }
  // get getIssuePermit(): boolean {
  //   console.log("Firearm Proxy", AuthModule.getIssuePermit);
  //   console.log("Firearm Permission", CRUDFunctionality.hasAccess("addPermit"));
  //   return AuthModule.getIssuePermit;
  // }
  async downloadPermit(item: OldPermit): Promise<void> {
    try {
      this.loading = true;
      const res = await Permits.getPermitPDF(item);
      // console.log("Get Permit: ", res);
      // const fileUrl = window.URL.createObjectURL(res);
      const buffer = await res.arrayBuffer();
      const saved: OfflinePermitType = {
        date: new Date(item.createdDate).toLocaleDateString(),
        id: item.id,
        buffer: buffer,
        title: `${item.armResponseOfficer.surname}, ${item.armResponseOfficer.name}`,
        subtitle: `${item.firearm.make}, ${item.firearm.model}, ${item.firearm.typeStr}`,
      };
      if (AuthModule.getUsername != null) {
        await IndexedDB.savePermit(saved, AuthModule.getUsername);
        this.$router.push({
          name: "dash.offlinePermits",
        });
      }
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  openReturnPermitModal(item: OldPermit): void {
    this.returnPermit.permit = item;
    this.returnPermit.open = true;
  }

  filterByStatus() {
    if (this.filterOnStatus != null && this.filterOnStatus.length > 0) {
      const filteredPermits: OldPermit[] = [];

      if (this.filterOnStatus.includes("Issued/ Pending Action")) {
        const tempIssued = this.permits.filter(
          (perm) =>
            perm.acceptedBy == null &&
            perm.isReturned == false &&
            perm.declineBy == null
        );
        if (tempIssued != undefined) {
          tempIssued.map((tempI) => filteredPermits.push(tempI));
        }
      }

      if (this.filterOnStatus.includes("Returned")) {
        const tempReturned = this.permits.filter(
          (perm) => perm.declineBy == null && perm.isReturned == true
        );
        if (tempReturned != undefined) {
          tempReturned.map((tempR) => filteredPermits.push(tempR));
        }
      }

      if (this.filterOnStatus.includes("Approved")) {
        console.log("filtering by approved");
        const tempAccepted = this.permits.filter(
          (perm) =>
            perm.declineBy == null &&
            perm.isReturned == false &&
            perm.acceptedBy != null
        );
        if (tempAccepted != undefined) {
          tempAccepted.map((tempA) => filteredPermits.push(tempA));
        }
      }

      if (this.filterOnStatus.includes("Declined")) {
        const tempDeclined = this.permits.filter(
          (perm) => perm.declineBy != null
        );
        if (tempDeclined != undefined) {
          tempDeclined.map((tempD) => filteredPermits.push(tempD));
        }
      }

      this.permits = filteredPermits;
    }
  }

  async loadAllPermits(): Promise<void> {
    console.log("Shift Sites", this.shiftSitesSelected);
    console.log("AROs", this.arosSelected);
    const res = await Permits.getAllPermits(
      {
        startDate: this.dateFilters.from.date,
        endDate: this.dateFilters.to.date,
      },
      this.shiftSitesSelected.map((e) => e.id!),
      this.arosSelected.map((e) => e && e.id!.toString()),
      [], // TODO@CVD: Check the filtering here
      this.outstandingPermits
    ).catch((err) => {
      console.log("Error all permits: ", err);
      return Promise.reject(err);
    });
    this.permits = res;
    this.filterByStatus();
  }
  async initialize(): Promise<void> {
    try {
      this.loading = true;
      //loading permits
      /* await this.loadAllPermits(); */
      await this.loadAros();
      await this.loadShiftSites();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  async handlePermit(permit: OldPermit): Promise<void> {
    if (this.getEnforcedSignature == true) {
      this.signatureOpen = true;
      this.permitToBeHandled = permit;
    } else {
      await this.confirmPermit(permit);
    }
  }

  async handleSignatureUpdate(b64: string): Promise<void> {
    console.log("Signature: ", b64);
    if (b64 != undefined && this.permitToBeHandled != null) {
      this.signature = b64;
      /* if (this.signatureAction == "approve") { */
      await this.confirmPermit(this.permitToBeHandled);
      /* } */
      /*  if (this.signatureAction == "confirm") {
        await this.confirmPermit(this.permitToBeHandled);
      } */
      this.signatureOpen = false;
    } else {
      this.$notifCreator.createErrorNotification(
        "Sorry! Something went wrong, Please try later"
      );
    }
  }

  get getEnforcedSignature(): boolean {
    if (Auth.getUserOverview) {
      if (
        Auth.getUserOverview.userSignatureID == null ||
        Auth.getUserOverview.userSignatureID == undefined
      ) {
        return true;
      } else {
        return Auth.getUserOverview.securityFirm.enforceSignatures;
      }
    } else {
      return false;
    }
  }
  async confirmPermit(perm: OldPermit): Promise<void> {
    var temp: ConfirmPermitViewModel = {};
    temp.assignFirearmId = perm.id;
    if (this.getEnforcedSignature == true && this.signature != null) {
      temp.confirmedBySignature = {
        fileName: "",
        fileSize: 0,
        mimeType: "",
        upload: this.signature,
      };
    }
    console.log("permit to be confirmed: ", temp);
    try {
      this.loading = true;
      const res = await Permits.confirmPermit(temp);
      this.initialize();
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  async mounted(): Promise<void> {
    try {
      await this.initialize();
      if (AuthModule.getUsername != null) {
        const storedPermits = await IndexedDB.getStoredPermits(
          AuthModule.getUsername
        );
      }
      this.expReports = this.populateExportData(this.getFilteredPermits);
      return Promise.resolve();
      // console.log("Result of Stored PErmits: ", storedPermits);
      // console.log("All Done setup");
    } catch (err) {
      return Promise.reject(err);
    }
  }
}
