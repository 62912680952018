
import { Component, PropSync, Vue } from "vue-property-decorator";
import OModal from "@/views/dashboard/overview/Modal.vue";
import { Dash } from "@/hooks";

@Component({
  components: {
    OModal,
  },
})
export default class Other extends Vue {
  @PropSync("loading") load!: boolean;
  overviewModal = false;
  firearmOutstanding: any[] = [];
  firearmBookedOutCountHeaders = [
    { text: "Make", value: "firearm.make" },
    { text: "Type", value: "firearm.typeStr" },
    { text: "S/N", value: "firearm.serialNumber" },
    { text: "From", value: "fromDateStr" },
    { text: "To", value: "toDateStr" },
    { text: "Expiry Date", value: "firearm.expiryDateStr" },
    { text: "Days to Expiry", value: "firearm.daysToExpiryCalc" },
    { text: "Currently Booked By", value: "armResponseOfficer.name" },
  ];
  /* firearmBookedOutCountOutstandingHeaders = [
    { text: "Make", value: "firearm.make" },
    { text: "Type", value: "firearm.typeStr" },
    { text: "S/N", value: "firearm.serialNumber" },
    { text: "Expiry Date", value: "firearm.expiryDateStr" },
    { text: "Days to Expiry", value: "firearm.daysToExpiryCalc" },
    { text: "Currently Booked By", value: "armResponseOfficer.name" },
  ]; */
  incidentCountHeaders = [
    { text: "Incident Number", value: "incidentNumber" },
    { text: "Incident Type", value: "incidentType.name" },
    { text: "Date", value: "date" },
    { text: "Time", value: "time" },
    { text: "ARO/SO", value: "armResponseOfficer.name" },
  ];
  ammunitionBookedOutHeaders = [
    { text: "Calibre", value: "calibre" },
    { text: "Total Purchased", value: "totalQuantity" },
    { text: "Currently Booked Out", value: "bookedOut" },
    { text: "Used in Incident", value: "usedInIncident" },
    { text: "Not Booked Back/Not Reported", value: "usedAmmunition" },
    { text: "Total Available", value: "totalAvailable" },
  ];
  currentShiftstHeaders = [
    { text: "Site/Area Name", value: "shiftSite.name" },
    { text: "Start Date", value: "startDate" },
    { text: "End Date", value: "endDate" },
    { text: "Start Time", value: "startTime" },
    { text: "End Time", value: "endTime" },
  ];
  td: any[] = [];
  modalData = {
    tableData: this.td,
    tableHeaders: this.firearmBookedOutCountHeaders,
    tableName: "",
    title: "",
    sortBy: "",
  };

  async openModal(i: {
    color: string;
    icon: string;
    title: string;
    value: number;
    name: string;
    colorCheck: string;
  }): Promise<void> {
    this.load = true;
    this.modalData.title = i.title;

    if (i.name == "currentShifts") {
      try {
        const res = await Dash.GetShiftListOnDuty(5);
        this.modalData.tableData = res;
        this.modalData.tableData.forEach((item, i) => {
          item.id = i + 1;
        });
        this.modalData.tableHeaders = this.currentShiftstHeaders;
        this.modalData.tableName = i.name;
        this.modalData.sortBy = "startDate";
      } catch (err) {
        return Promise.reject(err);
      }
    } else if (i.name == "firearmBookedOutCount") {
      try {
        const res = await Dash.GetAssignFirearmsBookedOut(6);
        this.modalData.tableData = res;
        this.modalData.tableData.forEach((item, i) => {
          item.id = i + 1;
        });
        this.modalData.tableHeaders = this.firearmBookedOutCountHeaders;
        this.modalData.tableName = i.name;
        this.modalData.sortBy = "expiryDate";
      } catch (err) {
        return Promise.reject(err);
      }
    } else if (i.name == "firearmBookedOutCountOutstanding") {
      try {
        const res = await Dash.GetAssignFirearmsPastDue(13);
        this.modalData.tableData = res;

        this.modalData.tableHeaders = this.firearmBookedOutCountHeaders;
        this.modalData.tableName = i.name;
        this.modalData.sortBy = "expiryDate";
      } catch (err) {
        return Promise.reject(err);
      }
    } else if (i.name == "ammunitionBookedOut") {
      try {
        const res = await Dash.GetAmmunitionListBookedOut(7);
        this.modalData.tableData = res;
        this.modalData.tableData.forEach((item, i) => {
          item.id = i + 1;
        });
        this.modalData.tableHeaders = this.ammunitionBookedOutHeaders;
        this.modalData.tableName = i.name;
        this.modalData.sortBy = "expiryDate";
      } catch (err) {
        return Promise.reject(err);
      }
    } else if (i.name == "incidentCount") {
      try {
        const res = await Dash.GetIncidentList(8);
        this.modalData.tableData = res;
        this.modalData.tableData.forEach((item, i) => {
          item.id = i + 1;
        });
        this.modalData.tableHeaders = this.incidentCountHeaders;
        this.modalData.tableName = i.name;
        this.modalData.sortBy = "expiryDate";
      } catch (err) {
        return Promise.reject(err);
      }
    }
    this.load = false;
    this.overviewModal = true;
  }

  colorEval(str: string): string {
    console.log(str);
    return eval(str);
  }
  async mounted(): Promise<void> {
    try {
      this.load = true;
      const res = await Dash.GetDashboardCounts();

      /* intercept with calc for outstandin */
      const resFirearmOutstanding = await Dash.GetAssignFirearmsPastDue(13);
      this.firearmOutstanding = resFirearmOutstanding;

      res.firearmBookedOutCountOutstanding = this.firearmOutstanding.length;

      this.dash.forEach((el) => {
        const index = Object.keys(res).find((i: string) => i == el.name);
        if (index != undefined) {
          el.value = res[index];
        }
      });
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.load = false;
    }
  }
  dash: {
    color: string;
    icon: string;
    title: string;
    value: number;
    name: string;
    colorCheck: string;
  }[] = [
    {
      color: "orange lighten-2",
      icon: "mdi-home",
      title: "Shifts currently on duty",
      value: -1,
      name: "currentShifts",
      colorCheck: "> 0",
    },
    /* {
      color: "red lighten-1",
      icon: "mdi-car-brake-alert",
      title: "Firearms currently booked out",
      value: -1,
      name: "firearmBookedOutCount",
      colorCheck: "> 0",
    }, */
    {
      color: "teal lighten-1",
      icon: "mdi-pistol",
      title: "Firearms currently booked out",
      value: -1,
      name: "firearmBookedOutCount",
      colorCheck: "> 0",
    },
    {
      color: "teal lighten-1",
      icon: "mdi-clock-alert-outline",
      title: "Firearm Permits Past Due Outstanding",
      value: -1,
      name: "firearmBookedOutCountOutstanding",
      colorCheck: "> 0",
    },
    {
      color: "teal lighten-1",
      icon: "mdi-bullet",
      title: "Ammunition Currently Booked out",
      value: -1,
      name: "ammunitionBookedOut",
      colorCheck: "> 0",
    },
    {
      color: "orange lighten-2",
      icon: "mdi-shield-alert-outline",
      title: "Number of Firearm related incidents in the past 90 days",
      value: -1,
      name: "incidentCount",
      colorCheck: "> 0",
    },
  ];
}
