import { OfflinePermitType } from "models";

const DB_NAME = "zero8db";
const PERMIT_NAME = "permits_";
// let DB_VERSION = 1;

// return new Promise((resolve, reject) => {

// })

const indexedDb = () => {
  let DB: IDBDatabase | null = null;
  const unmountDB = () => {
    // console.log("Unmount");
    DB = null;
  };
  const getDB = (
    currentUser?: string,
    version?: number
  ): Promise<IDBDatabase | null> => {
    return new Promise((resolve, reject) => {
      const request = window.indexedDB.open(DB_NAME, version);
      // console.log("Waiting for eventListeners now");
      request.onupgradeneeded = (e) => {
        // console.log("getDB onupgradeneeded");
        const db = (e.target as IDBOpenDBRequest).result;
        // console.log("Get DB objectStoreNames: ", db.objectStoreNames);
        if (currentUser != undefined) {
          if (
            db.objectStoreNames.contains(PERMIT_NAME + currentUser) == false
          ) {
            // createObjectStore(PERMIT_NAME + currentUser);
            console.log(
              "Create this:  ",
              PERMIT_NAME + currentUser.toLowerCase()
            );
            const store = db.createObjectStore(
              PERMIT_NAME + currentUser.toLowerCase(),
              {
                autoIncrement: true,
                keyPath: "id",
              }
            );
          }
        }
      };
      request.onblocked = (e) => {
        console.log("Blocked", e);
      };
      request.onerror = (e) => {
        console.log("Error opening db", e);
        reject(e);
      };
      request.onsuccess = (e) => {
        if (e == null || e.target == null) {
          console.log("Db opened succesfully, but null");
          resolve(null);
        } else {
          const db = (e.target as IDBOpenDBRequest).result;
          db.onversionchange = () => {
            db.close();
          };
          resolve(db);
        }
      };
    });
  };
  const getAllUsers = async (): Promise<string[]> => {
    const db = await getDB();
    if (db == null) {
      return Promise.reject("No DB... get stored");
    }
    const items = db.objectStoreNames;
    const empty: string[] = [];
    for (let i = 0; i < items.length; i++) {
      empty.push(items[i].replace(PERMIT_NAME, ""));
    }
    console.log("Saved DB list: ", items, empty);
    return empty;
  };
  const createObjectStore = async (
    currentUser: string,
    version: number
  ): Promise<IDBDatabase | null> => {
    return new Promise((resolve, reject) => {
      const request = window.indexedDB.open(DB_NAME, version + 1);
      request.onerror = (err) => {
        console.log("Upgrade error: ", err);
        reject(err);
      };
      request.onupgradeneeded = (up) => {
        console.log("Please upgrade here :", currentUser.toLowerCase());
        DB = (up.target as IDBOpenDBRequest).result;
        DB.createObjectStore(PERMIT_NAME + currentUser.toLowerCase(), {
          autoIncrement: true,
          keyPath: "id",
        });
      };
      request.onsuccess = (e) => {
        if (e == null || e.target == null) {
          console.log("Db opened succesfully, but null");
          resolve(null);
        } else {
          DB = (e.target as IDBOpenDBRequest).result;
          resolve((e.target as IDBOpenDBRequest).result);
        }
      };
    });
  };

  const deletePermit = async (
    id: number,
    currentUser: string
  ): Promise<void> => {
    let db = await getDB(currentUser.toLowerCase());
    if (db == null) {
      return Promise.reject("No DB... get stored");
    }
    if (
      db.objectStoreNames.contains(PERMIT_NAME + currentUser.toLowerCase()) ==
      false
    ) {
      console.log("Upgrade db version here", db.version, db.version + 1);
      db = await getDB(currentUser.toLowerCase(), db.version + 1);
      // console.log("after upgrading", db == null);
    } else {
      console.log("Upgrade not needed");
    }
    return new Promise((resolve) => {
      if (db == null) {
        return Promise.reject("No DB... get stored");
      }
      const trans = db.transaction(
        [PERMIT_NAME + currentUser.toLowerCase()],
        "readwrite"
      );
      trans.oncomplete = () => {
        resolve();
      };
      const store = trans.objectStore(PERMIT_NAME + currentUser.toLowerCase());
      store.delete(id);
    });
  };
  const getStoredPermits = async (
    currentUser: string
  ): Promise<OfflinePermitType[]> => {
    let db = await getDB(currentUser.toLowerCase());
    if (db == null) {
      return Promise.reject("No DB... get stored");
    }
    if (
      db.objectStoreNames.contains(PERMIT_NAME + currentUser.toLowerCase()) ==
      false
    ) {
      console.log("Upgrade db version here", db.version, db.version + 1);
      db = await getDB(currentUser.toLowerCase(), db.version + 1);
      console.log("after upgrading", db == null);
    } else {
      console.log("Upgrade not needed");
    }
    // console.log("Carry on");
    return new Promise((resolve, reject) => {
      if (db == null) {
        return reject("No DB... get storeed after upgrade");
      }
      const trans = db.transaction(
        [PERMIT_NAME + currentUser.toLowerCase()],
        "readwrite"
      );
      trans.onabort = () => {
        console.log("Abort transactions");
      };
      trans.onerror = () => {
        console.log("Error for stored permits on error");
      };
      trans.oncomplete = () => {
        // console.log("Trans complete");
        return resolve(permits);
      };

      // console.log("before store object store");
      let store;
      try {
        store = trans.objectStore(PERMIT_NAME + currentUser.toLowerCase());
      } catch (e) {
        console.log("Should create new table here");
        store = db.createObjectStore(PERMIT_NAME + currentUser.toLowerCase());
      }

      const permits: OfflinePermitType[] = [];

      store.openCursor().onsuccess = (e) => {
        const cursor = (e.target as IDBRequest).result;
        if (cursor) {
          // console.log("Here is saved permit: ", cursor);
          permits.push(cursor.value);
          cursor.continue();
        }
      };
    });
  };

  const savePermit = async (
    item: OfflinePermitType,
    currentUser: string
  ): Promise<void> => {
    let db = await getDB(currentUser.toLowerCase());
    if (db == null) {
      return Promise.reject("No DB... get stored");
    }
    if (
      db.objectStoreNames.contains(PERMIT_NAME + currentUser.toLowerCase()) ==
      false
    ) {
      console.log("Upgrade db version here", db.version, db.version + 1);
      db = await getDB(currentUser.toLowerCase(), db.version + 1);
      console.log("after upgrading", db == null);
    } else {
      console.log("Upgrade not needed");
    }
    return new Promise((resolve, reject) => {
      if (db == null) {
        return reject("No DB... save permit after upgrade");
      }
      const trans = db.transaction(
        [PERMIT_NAME + currentUser.toLowerCase()],
        "readwrite"
      );
      trans.oncomplete = () => {
        resolve();
      };
      const store = trans.objectStore(PERMIT_NAME + currentUser.toLowerCase());
      store.put(item);
    });
  };
  return {
    getDB,
    savePermit,
    getStoredPermits,
    deletePermit,
    createObjectStore,
    unmountDB,
    DB,
    getAllUsers,
  };
};
const temp = indexedDb();
export default temp;
