import { axiosInstance, axiosNoAuth } from "@/plugins/axios";
import {
  AmmunitionReturn,
  OldPermit,
  NewPermit,
  PermitCreate,
  DateInterval,
  AssignFirearm,
  ConfirmPermitViewModel,
} from "models";
import dayjs from "dayjs";
import axios from "axios";

/* Append extraneous fields */
function importPermit(old: OldPermit): NewPermit {
  return {
    ...old,
    initDateTime: dayjs(old.fromDate, old.fromTime),
    termDateTime: dayjs(old.toDate, old.toTime),
  };
}
/* Remove extraneous fields */
// function exportPermit(new: NewPermit): OldPermit {
//   // const { initDateTime: any, termDateTime: any, ...old } = new;
//   const { ...old } = new;
//   return old;
// }

const Permits = () => {
  const baseUrl = "/api/permitsAPI"; // internalURL
  const externalURL = "/api/publicpermits"; // externalURL

  /**
   * All Permits
   *
   * Retrieves all firearm permits.
   *
   * @returns Firearm Permits.
   */
  const getAllPermits = async (
    interval?: DateInterval,
    shiftSiteId?: number[],
    aroIds?: string[],
    firearmIds?: number[],
    outstandingPermits?: boolean
  ): Promise<OldPermit[]> => {
    const res = await axiosInstance.post(`${baseUrl}/getAllPermits`, {
      ...interval,
      shiftSiteId,
      aroIds,
      firearmIds,
      outstandingPermits,
    });
    console.log("Date Range Permit", res.data);
    return Promise.resolve(res.data);
  };
  /**
   * Permits
   *
   * Retrieves all firearm permits.
   *
   * @returns Firearm Permits.
   */
  // const getPermits = async (): Promise<void> => {
  //   // TODO@CVD: Deprecated ? This seems unused
  //   // TODO: Return Promise<OldPermit[]> ?
  //   const res = await axiosInstance
  //     .get(baseUrl + "/getPermits")
  //     .catch((err: any) => {
  //       return Promise.reject(err);
  //     });
  //   return Promise.resolve(res.data);
  // };
  /**
   * ARO Permits
   *
   * Retrieves all firearm permits for a specific ARO, determined by the backend.
   *
   * @returns Firearm Permits.
   */
  const getAROPermits = async (): Promise<OldPermit[]> => {
    const res = await axiosInstance.get(baseUrl + "/getAROPermits");
    console.log("Test aro permits ", res);
    return Promise.resolve(res.data);
  };
  /**
   * All ARO Permits
   *
   * @returns Firearm Permits.
   */
  // const getAllAROPermits = async (): Promise<OldPermit[]> => {
  //   // TODO@CVD: Deprecated ? This seems unused
  //   const res = await axiosInstance
  //     .get(baseUrl + "/getAllAROPermits")
  //     .catch((err: any) => {
  //       if (err.response.data) {
  //         return Promise.reject(err.response.data);
  //       }
  //       return Promise.reject(err);
  //     });
  //   return Promise.resolve(res.data);
  // };
  /**
   * All Historic Permits
   *
   * @returns Firearm Permits.
   */
  // const getAllHistoricPermits = async (
  //   data: DateInterval
  // ): Promise<OldPermit[]> => {
  //   // TODO@CVD: Deprecated ? This seems unused
  //   const res = await axiosInstance
  //     .get(`${baseUrl}/getAllPermits`, { params: data })
  //     .catch((err: any) => {
  //       if (err.response.data) {
  //         return Promise.reject(err.response.data);
  //       }
  //       return Promise.reject(err);
  //     });
  //   console.log("Date Range Permit", res.data);
  //   return Promise.resolve(res.data);
  // };
  /**
   * Permit Defaults
   *
   * @param newPerm
   * @returns
   */
  const getPermitDefaults = async (parameters: any): Promise<void> => {
    // URLSearchParams
    const res = await axiosInstance.get(baseUrl + "/getPermitFormData", {
      params: parameters,
    });
    console.log("Permit Default", res.data);
    return Promise.resolve(res.data);
  };
  /**
   * Issue Permit
   *
   * @param newPerm
   * @returns
   */
  const issuePermit = async (newPerm: PermitCreate): Promise<void> => {
    // TODO: Return Promise<OldPermit> ?
    const res = await axiosInstance.post(baseUrl + "/issuePermit", newPerm);
    console.log("Date Range Permit", res.data);
    return Promise.resolve(res.data);
  };
  const getAutoPopulateFirearm = async (
    aroId: number
  ): Promise<any[] | null> => {
    const res = await axiosInstance.get(
      `${baseUrl}/getAutoPopulateFirearm?aroId=${aroId}`
    );
    return Promise.resolve(res.data);
  };
  const getAssignFirearmFields = async (
    aroId: number,
    firearmId: number
  ): Promise<any[] | null> => {
    const res = await axiosInstance.get(
      `${baseUrl}/getAssignFirearmFields?aroId=${aroId}&firearmId=${firearmId}`
    );
    return Promise.resolve(res.data);
  };
  /**
   * Approve Permit
   *
   * Approves an issued permit
   *
   * @param permit
   * @returns
   */
  const approvePermit = async (permit: OldPermit): Promise<void> => {
    const res = await axiosInstance.put(
      `${baseUrl}/approvePermit?id=${permit.id}`,
      {
        ...permit,
        deviceID: "123123", // TODO: Is this magic number not meant to be defiend else where
      }
    );
    return Promise.resolve(res.data);
  };

  /**
   * Confirm Permit
   *
   * Confirms an issued permit
   *
   * @param permit
   * @returns
   */

  const confirmPermit = async (data: ConfirmPermitViewModel): Promise<void> => {
    const res = await axiosInstance.post(`${baseUrl}/confirmPermit`, data);
    return Promise.resolve(res.data);
  };
  /**
   * Decline Permit
   *
   * Declines an issued permit
   *
   * @param permit
   * @returns
   */
  const declinePermit = async (permit: OldPermit): Promise<void> => {
    const res = await axiosInstance.put(
      `${baseUrl}/declinePermit?id=${permit.id}`,
      {
        ...permit,
        deviceID: "123123",
      }
    );
    return Promise.resolve(res.data);
  };
  /**
   * Return Permit
   *
   * @param ret
   * @returns
   */
  const returnPermit = async (ret: AmmunitionReturn): Promise<void> => {
    // TODO: Return Promise<OldPermit> ?
    const res = await axiosInstance.post(baseUrl + "/returnPermit", ret);
    console.log("Return Permit", res.data);
    return Promise.resolve(res.data);
  };
  /**
   * PDF Permit (Private)
   *
   * Retrieves the compiled permit as a PDF.
   *
   * @param perm
   * @returns
   */
  const getPermitPDF = async (perm: OldPermit): Promise<Blob> => {
    const res = await axiosInstance
      .get(`${baseUrl}/getPermitPDF?assignFirearmID=${perm.id}`, {
        responseType: "blob",
      })
      .catch(async (err) => {
        // type blob
        if (err.response.data instanceof Blob) {
          console.log("LoOOOLL blob: ", err.response.data);
          return new Promise<any>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e: Event) => {
              try {
                const errmsg = JSON.parse((<any>e.target).result);
                console.log("Check msg ", errmsg);
                err.response.data = errmsg;
                reject(err);
              } catch (e) {
                reject(err);
              }
            };
            reader.onerror = (e) => {
              reject(err);
            };
            reader.readAsText(err.response.data);
          });
        }
        return Promise.reject(err);
      });

    return Promise.resolve(res.data);
  };
  /**
   * PDF Permit (Public)
   *
   * This gets the same document as getPermitPDF does but using the publicly visible API
   *
   * @param firearmPermit
   * @returns
   */
  const getFirearmPermitPDF = async (firearmPermit: string): Promise<Blob> => {
    // Get File as blob; requires some extra work to get the MIME type correct and it was easier to use ArrayBuffers.
    // const res = await axiosNoAuth
    //   .get(`${externalURL}/getFirearmPermit?id=${firearmPermit}`, {
    //     responseType: "blob",
    //   })
    //   .catch((err: any) => {
    //     if (err.response.data) {
    //       return Promise.reject(err.response.data);
    //     }
    //     return Promise.reject(err);
    //   });
    // return Promise.resolve(res.data);
    const res = await axiosNoAuth.get(
      `${externalURL}/getFirearmPermit?id=${firearmPermit}`,
      {
        responseType: "arraybuffer",
        headers: {
          Accept: "application/pdf",
        },
      }
    );
    return Promise.resolve(res.data);
    // Convert Array Buffer to FileSave
    // .then(response => {
    //     console.log(response);
    //     // response.data is an empty object
    //     const blob = new Blob([response.data], {
    //       type: 'application/pdf',
    //     });
    //     FileSaver.saveAs(blob, Math.random());
    // });
  };
  /**
   * Date Range Permits
   *
   * @param startDate
   * @param endDate
   * @returns
   */
  // const getDateRangePermits = async (
  //   startDate: string,
  //   endDate: string
  // ): Promise<void> => {
  //   // TODO: Return Promise<OldPermit> ?
  //   const res = await axiosInstance
  //     .get(
  //       `${baseUrl}/getDateRangePermits?startDate=${startDate}&endDate=${endDate}`
  //     )
  //     .catch((err: any) => {
  //       return Promise.reject(err);
  //     });
  //   console.log("Date Range Permit", res.data);
  //   return Promise.resolve(res.data);
  // };
  return {
    issuePermit,
    approvePermit,
    declinePermit,
    returnPermit,
    confirmPermit,
    // getPermits,
    getAllPermits,
    // getDateRangePermits,
    // getAllAROPermits,
    getPermitDefaults,
    getAROPermits,
    getPermitPDF,
    getFirearmPermitPDF,
    getAutoPopulateFirearm,
    getAssignFirearmFields,
    // getAllHistoricPermits,
  };
};
const temp = Permits();
export default temp;
